/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';
import { Card, InputAdornment, TextField } from '@mui/material';
import AdminSlipsTable from '../Components/AdminSlipsTable';
import SlipModal from '../Components/SlipModal';
import { Button } from '@material-ui/core';

import { makeStyles, Theme } from '@material-ui/core/styles';

import { Search } from '@mui/icons-material';

import { useCookies } from 'react-cookie';

import SearchApi, { INDEX_MODES } from 'js-worker-search';
import { trackPromise } from 'react-promise-tracker';
import adminReportsAPI from '../API/adminReportsAPI';

import { Slip, Slips, User } from '../@types/InterfaceDefinitions';

const searchApi = new SearchApi({
	indexMode: INDEX_MODES.PREFIXES,
});

const useStyles = makeStyles((theme: Theme) => ({
	slipPanelContainer: {
		display: 'flex',
		flexDirection: 'column',
		padding: '10px 0px',
		width: '100%',
	},
	tableContainer: {
		display: 'flex',
		margin: '0px auto',
		justifyContent: 'flex-start',
		width: '95%',
		height: 600,
		[theme.breakpoints.down('lg')]: {
			height: 425,
		},
	},
	slipMetrics: {
		display: 'flex',
		margin: '0px auto',
		width: '95%',
		justifyContent: 'flex-start',
	},
	slipMetricsTitle: {
		textDecoration: 'underline',
		textTransform: 'uppercase',
		fontFamily: `'League Spartan', sans-serif`,
		color: '#707070',
		marginBottom: '10px',
	},
	slipMetricsCard: {
		width: '95%',
		height: 100,
		margin: '0px auto',
	},
	slipMetricsContainer: {
		display: 'flex',
		justifyContent: 'space-evenly',
		height: 100,
		alignItems: 'center',
	},
	slipMetricsLabel: {
		color: '#707070',
		textTransform: 'uppercase',
		textDecoration: 'underline',
		fontWeight: 'bold',
		marginBottom: '10px',
		fontSize: 20,
	},
	column: {
		display: 'flex',
		flexDirection: 'column',
	},
	slipMetricsValue: {
		color: '#d65200',
		fontSize: 26,
		fontWeight: 'bold',
	},
	slipTableContainer: {
		display: 'flex',
		margin: '20px auto',
		justifyContent: 'space-between',
		width: '95%',
		alignItems: 'flex-end',
	},
	slipTableTitle: {
		textDecoration: 'underline',
		textTransform: 'uppercase',
		fontFamily: `'League Spartan', sans-serif`,
		color: '#707070',
	},
	nextTicketButton: {
		backgroundColor: '#000352 !important',
		color: '#ffffff !important',
	},
	searchInput: {
		paddingRight: '17px !important',
	},
}));

interface IAdminSlipPanelProps {
	allSlips: Slips;
	user: User;
	selectedSlip: Slip | null;
	setSelectedSlip: (slip: Slip | null) => void;
	handleUpdateAdminSlips: any;
}

function AdminSlipPanel(props: IAdminSlipPanelProps) {
	const [filteredData, setFilteredData] = React.useState([] as Slips);
	const [searchValue, setSearchValue] = React.useState('');
	const [slipModalOpen, setSlipModalOpen] = React.useState(false);
	const [selectedSlip, setSelectedSlip] = React.useState(
		props.selectedSlip as Slip | null
	);

	const classes = useStyles();

	const [cookies] = useCookies(['token']);
	const token = cookies.token;

	props.allSlips.forEach((slip: Slip) => {
		searchApi.indexDocument(slip.firstName, `${slip.firstName}`);
		searchApi.indexDocument(slip.lastName, `${slip.lastName}`);
		searchApi.indexDocument(slip.dealership, `${slip.dealership}`);
		searchApi.indexDocument(slip.callBackNumber, `${slip.callBackNumber}`);
		searchApi.indexDocument(slip.dealerPhone, `${slip.dealerPhone}`);
		searchApi.indexDocument(slip.actionCode, `${slip.actionCode}`);
	});

	const search = async (value: string, data: Slips) => {
		if (!value) {
			setFilteredData([]);
		}
		const filteredResults = new Set();
		try {
			const searchResults = await searchApi.search(value);
			data.forEach((el: Slip) => {
				searchResults.forEach((res: string) => {
					if (Object.values(el).includes(res)) {
						filteredResults.add(el);
					}
				});
			});
			if (filteredResults.size === 0) {
				setSearchValue('');
			} else if (
				filteredResults.size > 0 &&
				filteredResults.size < data.length
			) {
				setFilteredData(
					Array.from(filteredResults).sort((a: any, b: any) =>
						b.callDate.localeCompare(a.callDate)
					) as Slips
				);
			}
		} catch (error) {
			console.error(error);
		}
	};

	const handleSlipModalToggle = async (selectedSlip: Slip | null) => {
		if (!slipModalOpen && !props.selectedSlip) {
			try {
				await trackPromise(
					adminReportsAPI.reserveSlip(
						selectedSlip,
						props.user.id,
						token
					)
				);
			} catch (error) {
				console.error(error);
			}
		}
		setSlipModalOpen(!slipModalOpen);
		setSelectedSlip(selectedSlip);
		props.setSelectedSlip(selectedSlip);
	};

	const handleFormatPhone = (phone: string) => {
		const cleaned = ('' + phone).replace(/\D/g, '');
		const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
		if (match) {
			return '(' + match[1] + ') ' + match[2] + '-' + match[3];
		}
	};

	const handleGetNextSlip = async () => {
		try {
			const nextSlipResponse = await trackPromise(
				adminReportsAPI.getNextAvailableSlip(token)
			);
			handleSlipModalToggle(nextSlipResponse.data);
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		if (props.selectedSlip !== null) {
			setSelectedSlip(props.selectedSlip);
			setSlipModalOpen(true);
		}
	}, [props.selectedSlip, selectedSlip]);

	return (
		<div className={classes.slipPanelContainer}>
			<SlipModal
				slipModalOpen={slipModalOpen}
				handleFormatPhone={handleFormatPhone}
				selectedSlip={selectedSlip}
				handleSlipModalToggle={handleSlipModalToggle}
				setSlipModalOpen={setSlipModalOpen}
				handleUpdateAdminSlips={props.handleUpdateAdminSlips}
				user={props.user}
			/>
			<div className={classes.slipMetrics}>
				<span className={classes.slipMetricsTitle}>Tickets:</span>
			</div>
			<Card className={classes.slipMetricsCard}>
				<div className={classes.slipMetricsContainer}>
					<div className={classes.column}>
						<span className={classes.slipMetricsLabel}>
							Tickets in Queue
						</span>
						<span className={classes.slipMetricsValue}>50</span>
					</div>
					<div className={classes.column}>
						<span className={classes.slipMetricsLabel}>
							Call Back Time
						</span>
						<span className={classes.slipMetricsValue}>
							22 Minutes
						</span>
					</div>
					<div>
						<Button
							className={classes.nextTicketButton}
							onClick={() => handleGetNextSlip()}
							variant='contained'>
							Next Available Ticket
						</Button>
					</div>
				</div>
			</Card>
			<div className={classes.slipTableContainer}>
				<span className={classes.slipTableTitle}>
					Training Sessions:
				</span>
				<TextField
					className={classes.searchInput}
					placeholder='Search the table...'
					variant='standard'
					InputProps={{
						startAdornment: (
							<InputAdornment position='start'>
								<Search />
							</InputAdornment>
						),
						type: 'search',
					}}
					onChange={(event) =>
						search(event.target.value, props.allSlips as Slips)
					}
				/>
			</div>
			<div className={classes.tableContainer}>
				<AdminSlipsTable
					slips={props.allSlips}
					filteredSlips={filteredData}
					handleSlipModalToggle={handleSlipModalToggle}
					{...props}
				/>
			</div>
		</div>
	);
}

export default AdminSlipPanel;
