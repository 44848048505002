/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { useCookies } from 'react-cookie';

import AdminDispositionTable from '../Components/AdminDispositionTable';
import DatePicker from '../Components/DatePicker';
import adminReportsAPI from '../API/adminReportsAPI';

import moment from 'moment';

import { makeStyles, Theme } from '@material-ui/core/styles';

import { Trainer, Dispositions } from '../@types/InterfaceDefinitions';

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		padding: '18px 0px 10px 0px',
		width: '100%',
	},
}));
interface IAdminDispositionPanelProps {
	allDispositions: Dispositions;
	setAllDispositions: (dispositions: Dispositions) => void;
}

function AdminDispositionPanel(props: IAdminDispositionPanelProps) {
	const [tableData, setTableData] = React.useState(props.allDispositions);

	const [startDate, setStartDate] = React.useState(
		moment(Date.now()).format('MM/DD/YY')
	);
	const [endDate, setEndDate] = React.useState(
		moment(Date.now()).format('MM/DD/YY')
	);
	const [datePickerOpen, setDatePickerOpen] = React.useState(false);

	const classes = useStyles();

	const [cookies] = useCookies(['token']);

	const token = cookies.token;

	let totalSessions = 0;
	let totalCLM = 0;
	let totalCC = 0;
	let totalINCPS = 0;
	let totalLimit1 = 0;
	let totalLimit2 = 0;
	let totalNoAns = 0;
	let totalOGCG = 0;

	tableData.forEach((trainer: Trainer) => {
		totalSessions += trainer.SessionDone ? trainer.SessionDone : 0;
		totalCLM += trainer.CLM ? trainer.CLM : 0;
		totalCC += trainer.CC ? trainer.CC : 0;
		totalINCPS += trainer.INCPS ? trainer.INCPS : 0;
		totalLimit1 += trainer.Limit1 ? trainer.Limit1 : 0;
		totalLimit2 += trainer.Limit2 ? trainer.Limit2 : 0;
		totalNoAns += trainer.NoAnswer ? trainer.NoAnswer : 0;
		totalOGCG += trainer.OGCG ? trainer.OGCG : 0;
	});

	const totalData = [
		{
			trainer: 'Totals:',
			sessions: totalSessions,
			clm: totalCLM,
			cc: totalCC,
			incps: totalINCPS,
			limit1: totalLimit1,
			limit2: totalLimit2,
			noAns: totalNoAns,
			ogcg: totalOGCG,
		},
	];

	const handleDatePickerToggle = () => {
		setDatePickerOpen(!datePickerOpen);
		setStartDate(moment(Date.now()).format('MM/DD/YY'));
		setEndDate(moment(Date.now()).format('MM/DD/YY'));
	};

	const handleDateChange = async (start: number, end: number) => {
		setStartDate(moment(start).format('MM/DD/YY'));
		setEndDate(moment(end).format('MM/DD/YY'));
		try {
			const response = await trackPromise(
				adminReportsAPI.getSlipDispositions(start, end, token)
			);
			const usersObj: any = {};
			const updatedUsersArr: Dispositions = [];
			for (const u in response.data) {
				const { name, final_status, total } = response.data[u];
				if (name in usersObj) {
					usersObj[name][final_status] = total;
				} else {
					usersObj[name] = {
						name,
						[final_status]: total,
					};
				}
			}

			for (const key in usersObj) {
				updatedUsersArr.push(usersObj[key]);
			}
			props.setAllDispositions(updatedUsersArr);
		} catch (error) {
			console.error(error);
		}
		setDatePickerOpen(false);
	};

	useEffect(() => {
		if (tableData !== props.allDispositions) {
			setTableData(props.allDispositions);
		}
	}, [tableData, props.allDispositions]);

	return (
		<div className={classes.root}>
			<DatePicker
				datePickerOpen={datePickerOpen}
				handleDatePickerToggle={handleDatePickerToggle}
				handleDateChange={handleDateChange}
				startDate={startDate}
				endDate={endDate}
				setStartDate={setStartDate}
				setEndDate={setEndDate}
			/>
			<AdminDispositionTable
				tableData={tableData}
				totalsData={totalData}
				startDate={startDate}
				endDate={endDate}
				handleDatePickerToggle={handleDatePickerToggle}
			/>
		</div>
	);
}

export default AdminDispositionPanel;
