import React, { forwardRef } from 'react';
import MaterialTable, { Icons } from 'material-table';

import AddBox from '@material-ui/icons/AddBox';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

import { makeStyles, Theme } from '@material-ui/core/styles';

import { KeyboardArrowDown } from '@mui/icons-material';
import { trackPromise } from 'react-promise-tracker';
import trainerReportsAPI from '../API/trainerReportsAPI';

import { useCookies } from 'react-cookie';

import { DownTimes } from '../@types/InterfaceDefinitions';
import moment from 'moment';

const tableIcons: Icons = {
	Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
	Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
	Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
	DetailPanel: forwardRef((props, ref) => (
		<ChevronRight {...props} ref={ref} />
	)),
	Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
	Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
	Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
	FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
	LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
	NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	PreviousPage: forwardRef((props, ref) => (
		<ChevronLeft {...props} ref={ref} />
	)),
	ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
	SortArrow: forwardRef((props, ref) => (
		<KeyboardArrowDown style={{ color: '#ffffff' }} {...props} ref={ref} />
	)),
	ThirdStateCheck: forwardRef((props, ref) => (
		<Remove {...props} ref={ref} />
	)),
	ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const useStyles = makeStyles((theme: Theme) => ({
	totals: {
		height: 40,
		width: '100%',
		border: '1px solid #dadad9',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		paddingRight: 90,
		color: '#d65200',
		fontWeight: 'bold',
		fontSize: '16px',
	},
	marginRight: {
		marginRight: 10,
	},
}));

interface IDowntimeTableProps {
	tableRef: any;
	downTimeRequests: DownTimes;
	handleUpdateDowntime: any;
}

function DownTimeTable(props: IDowntimeTableProps) {
	const classes = useStyles();
	let totalMinutes: number = 0;
	props.downTimeRequests.forEach((request: any) => {
		totalMinutes += request.minutes;
	});

	const [cookies] = useCookies(['token']);

	const token: string = cookies.token;

	const handleRemoveRequest = async (event: any, request: any) => {
		try {
			await trackPromise(
				trainerReportsAPI.removeDownTime(request.id, token)
			);
		} catch (error) {
			console.error(error);
		}
		props.handleUpdateDowntime();
	};

	return (
		<React.Fragment>
			<MaterialTable
				tableRef={props.tableRef}
				icons={tableIcons}
				columns={[
					{
						title: 'Description',
						field: 'description',
						align: 'left',
						cellStyle: {
							width: 500,
						},
					},
					{
						title: 'Start Time',
						field: 'start_time',
						align: 'center',
						cellStyle: {
							width: 150,
						},
						render: (rowData: any) => {
							return moment(rowData.start_time * 1000).format(
								'hh:mm A'
							);
						},
					},
					{
						title: 'End Time',
						field: 'end_time',
						align: 'center',
						cellStyle: {
							width: 150,
						},
						render: (rowData: any) => {
							return moment(rowData.end_time * 1000).format(
								'hh:mm A'
							);
						},
					},
					{
						title: 'Minutes',
						field: 'minutes',
						align: 'center',
						cellStyle: {
							width: 100,
						},
					},
				]}
				data={props.downTimeRequests}
				style={{
					fontFamily: 'roboto, "Open Sans", Helvetica, sans-serif',
					boxShadow: 'none',
				}}
				localization={{
					header: {
						actions: 'Remove',
					},
				}}
				options={{
					sorting: false,
					draggable: false,
					actionsColumnIndex: -1,
					search: false,
					showTitle: false,
					paging: false,
					toolbar: false,
					headerStyle: {
						backgroundColor: '#000352',
						color: '#ffffff',
						borderRight: '2px solid #dadad9',
						textAlign: 'center',
						fontSize: '15px',
						fontWeight: 500,
						textTransform: 'uppercase',
						fontFamily: `'League Spartan', sans-serif`,
						maxHeight: '40px',
						padding: 0,
					},
					padding: 'dense',
					rowStyle: (rowData, index) => {
						if (index % 2) {
							return {
								backgroundColor: '#f3f3f3',
								padding: '0px 16px',
								height: 40,
							};
						} else {
							return { padding: '0px 16px', height: 40 };
						}
					},
					maxBodyHeight: window.innerHeight > 900 ? 200 : 200,
				}}
				actions={[
					{
						icon: () => <DeleteOutline />,
						tooltip: 'Remove',
						onClick: (event, rowData) =>
							handleRemoveRequest(event, rowData),
					},
				]}
			/>
			<div className={classes.totals}>
				<span className={classes.marginRight}>Total Minutes:</span>
				<span>{totalMinutes}</span>
			</div>
		</React.Fragment>
	);
}

export default DownTimeTable;
