import apiClient from './apiClient';

const loginAPI = {
	login(token: string) {
		return apiClient.get('/user/login', {
			headers: {
				'Content-Type': 'application/json',
				authorization: 'Basic ' + token,
			},
		});
	},

	logout() {
		return apiClient.post('/user/logout');
	},
};

export default loginAPI;
