import React from 'react';

import { ListItemIcon, ListItemText } from '@material-ui/core';

import ListItemButton from '@mui/material/ListItemButton';

import { InsertChart, ViewQuilt, TableChart } from '@mui/icons-material';

import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
	listItem: {
		'& span': {
			letterSpacing: 'normal',
			fontSize: '14px',
			textTransform: 'uppercase',
		},
		[theme.breakpoints.down('lg')]: {
			'& span': {
				fontSize: '12px',
			},
		},
	},
	listItemIcon: {
		color: '#ffffff',
		marginLeft: '26px',
		[theme.breakpoints.down('lg')]: {
			marginLeft: '10px',
		},
	},
	navListItem: {
		paddingBottom: '5px',
		marginBottom: '10px',
		paddingTop: '3px',
	},
}));

function NavList(props: any) {
	const classes = useStyles();
	return (
		<React.Fragment>
			<ListItemButton
				style={{
					backgroundColor:
						props.selectedDrawerButton === 0
							? '#707070'
							: 'transparent',
				}}
				onClick={() => {
					props.handleDrawerNavClick(0);
				}}>
				<ListItemIcon className={classes.listItemIcon}>
					<ViewQuilt />
				</ListItemIcon>
				<ListItemText
					className={classes.listItem}
					primary='Applications'
				/>
			</ListItemButton>
			<ListItemButton
				style={{
					backgroundColor:
						props.selectedDrawerButton === 1
							? '#707070'
							: 'transparent',
				}}
				onClick={() => {
					props.handleDrawerNavClick(1);
				}}>
				<ListItemIcon className={classes.listItemIcon}>
					<InsertChart />
				</ListItemIcon>
				<ListItemText className={classes.listItem} primary='Reports' />
			</ListItemButton>
			<ListItemButton
				style={{
					backgroundColor:
						props.selectedDrawerButton === 2
							? '#707070'
							: 'transparent',
				}}
				onClick={() => {
					props.handleDrawerNavClick(2);
				}}>
				<ListItemIcon className={classes.listItemIcon}>
					<TableChart />
				</ListItemIcon>
				<ListItemText
					className={classes.listItem}
					primary='SLX Dispositions'
				/>
			</ListItemButton>
		</React.Fragment>
	);
}

export default NavList;
