import React from 'react';

import { makeStyles, Theme } from '@material-ui/core/styles';

import {
	SlipDisposition,
	SlipDispositions,
} from '../@types/InterfaceDefinitions';

const useStyles = makeStyles((theme: Theme) => ({
	dispositionTableCell: {
		width: 115,
		height: 40,
		border: '1px solid #707070',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		[theme.breakpoints.down('lg')]: {
			width: 85,
			height: 30,
		},
	},
	flex: {
		display: 'flex',
	},
	tableHeader: {
		display: 'flex',
		backgroundColor: '#000352',
		color: '#ffffff',
		fontFamily: `'League Spartan', sans-serif`,
		textTransform: 'uppercase',
	},
	tableContainer: {
		display: 'flex',
		flexDirection: 'column',
		border: '1px solid #707070',
	},
}));

interface IDispositionTableCellProps {
	slipDispositions: SlipDispositions;
}

function DispositionTable(props: IDispositionTableCellProps) {
	const classes = useStyles();

	let clm: number = 0;
	let cc: number = 0;
	let incps: number = 0;
	let limit1: number = 0;
	let limit2: number = 0;
	let noAns: number = 0;
	let og: number = 0;
	let sessions: number = 0;

	props.slipDispositions.forEach((disposition: SlipDisposition) => {
		switch (disposition.disposition) {
			case 'CLM':
				clm += disposition.total;
				break;
			case 'CC':
				cc += disposition.total;
				break;
			case 'INCPS':
				incps += disposition.total;
				break;
			case 'Limit 1':
				limit1 += disposition.total;
				break;
			case 'Limit 2':
				limit2 += disposition.total;
				break;
			case 'NOANS':
				noAns += disposition.total;
				break;
			case 'OG':
				og += disposition.total;
				break;
			case 'Session done':
				sessions += disposition.total;
				break;
		}
	});

	return (
		<div className={classes.tableContainer}>
			<div className={classes.tableHeader}>
				<div className={classes.dispositionTableCell}>Type</div>
				<div className={classes.dispositionTableCell}>Qty.</div>
				<div className={classes.dispositionTableCell}>Type</div>
				<div className={classes.dispositionTableCell}>Qty.</div>
			</div>
			<div className={classes.flex}>
				<div className={classes.dispositionTableCell}>CLM</div>
				<div className={classes.dispositionTableCell}>{clm}</div>
				<div className={classes.dispositionTableCell}>Limit 2</div>
				<div className={classes.dispositionTableCell}>{limit2}</div>
			</div>
			<div className={classes.flex}>
				<div className={classes.dispositionTableCell}>CC</div>
				<div className={classes.dispositionTableCell}>{cc}</div>
				<div className={classes.dispositionTableCell}>No Ans</div>
				<div className={classes.dispositionTableCell}>{noAns}</div>
			</div>
			<div className={classes.flex}>
				<div className={classes.dispositionTableCell}>INCPS</div>
				<div className={classes.dispositionTableCell}>{incps}</div>
				<div className={classes.dispositionTableCell}>OG/CG</div>
				<div className={classes.dispositionTableCell}>{og}</div>
			</div>
			<div className={classes.flex}>
				<div className={classes.dispositionTableCell}>Limit 1</div>
				<div className={classes.dispositionTableCell}>{limit1}</div>
				<div className={classes.dispositionTableCell}>Sessions</div>
				<div className={classes.dispositionTableCell}>{sessions}</div>
			</div>
		</div>
	);
}

export default DispositionTable;
