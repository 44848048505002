/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';
import { AppBar, Button, TextField, Toolbar } from '@material-ui/core';

import { trackPromise } from 'react-promise-tracker';

import Logo from '../Assets/White Logo.png';

import loginAPI from '../API/loginAPI';

import { makeStyles } from '@material-ui/core/styles';

import { useCookies } from 'react-cookie';

import { useHistory } from 'react-router-dom';

import { User } from '../@types/InterfaceDefinitions';

const useStyles = makeStyles((theme) => ({
	appBar: {
		height: '100px',
		width: '100%',
		backgroundColor: '#000352',
		display: 'flex',
		[theme.breakpoints.down('lg')]: {
			height: '75px',
		},
	},
	headerLogo: {
		height: '100px',
		width: '300px',
		marginLeft: '40px',
		[theme.breakpoints.down('lg')]: {
			width: '233px',
			height: '75px',
			marginLeft: '20px',
		},
	},
	loginForm: {
		display: 'flex',
		flexDirection: 'column',
		width: '400px',
		margin: '0 auto',
		border: '1px solid #dadad9',
		padding: 50,
		borderRadius: 3,
	},
	loginTitle: {
		textDecoration: 'underline',
		fontWeight: 'bold',
		fontSize: '24px',
		marginBottom: '20px',
	},
	loginInput: {
		marginTop: '20px',
		minWidth: '300px',
	},
	loginButton: {
		color: '#ffffff',
		backgroundColor: '#000352',
		marginTop: '20px',
	},
}));
interface ILoginProps {
	getUser: (user: User) => void;
	handleUserLoggedIn: (value: boolean) => void;
}

function LoginPage(props: ILoginProps): JSX.Element {
	const [username, setUsername] = React.useState('');
	const [password, setPassword] = React.useState('');

	const history = useHistory();

	const classes = useStyles();

	const [cookies, setCookie, removeCookie] = useCookies(['token']);

	const handleChangeUsername = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setUsername(event.target.value);
	};

	const handleChangePassword = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setPassword(event.target.value);
	};

	const handleLogin = async () => {
		const token = btoa(username + ':' + password);
		try {
			const response = await trackPromise(loginAPI.login(token));
			props.getUser(response.data);
			setCookie('token', token, {});
			props.handleUserLoggedIn(true);
			history.push('/');
		} catch (error) {
			alert('Invalid username or password');
			removeCookie('token', {});
			console.error(error);
		}
	};

	return (
		<div>
			<AppBar position='fixed' className={classes.appBar}>
				<Toolbar>
					<img
						className={classes.headerLogo}
						alt='Applied Concepts Logo'
						src={Logo}
					/>
				</Toolbar>
			</AppBar>
			<div className={classes.loginForm}>
				<span className={classes.loginTitle}>Login</span>
				<TextField
					variant='outlined'
					className={classes.loginInput}
					label='Username'
					onChange={handleChangeUsername}
					value={username}
				/>
				<TextField
					variant='outlined'
					className={classes.loginInput}
					type='password'
					label='Password'
					onChange={handleChangePassword}
					value={password}
				/>
				<Button
					className={classes.loginButton}
					variant='contained'
					type='submit'
					onClick={() => handleLogin()}>
					Login
				</Button>
			</div>
		</div>
	);
}

export default LoginPage;
