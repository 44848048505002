import apiClient from './apiClient';

const trainerReportsAPI = {
	getSlipTotals(trainerId: string, from: any, to: any, token: string) {
		return apiClient.post(
			`/slips/total/${trainerId}`,
			{
				datefrom: from,
				dateto: to,
			},
			{
				headers: {
					'Content-Type': 'application/json',
					authorization: 'Basic ' + token,
				},
			}
		);
	},

	getSLXSDispositions(from: any, to: any, token: string) {
		return apiClient.post(
			'/trainingsessions/dispositions',
			{ from: from, to: to },
			{
				headers: {
					'Content-Type': 'application/json',
					authorization: 'Basic ' + token,
				},
			}
		);
	},

	getSlipDispositions(trainerId: string, from: any, to: any, token: string) {
		return apiClient.post(
			`/slips/dispositions/${trainerId}`,
			{
				datefrom: from,
				dateto: to,
			},
			{
				headers: {
					'Content-Type': 'application/json',
					authorization: 'Basic ' + token,
				},
			}
		);
	},

	getCompletedSlips(trainerId: string, from: any, to: any, token: string) {
		return apiClient.post(
			`/slips/completed/${trainerId}`,
			{
				datefrom: from,
				dateto: to,
			},
			{
				headers: {
					'Content-Type': 'application/json',
					authorization: 'Basic ' + token,
				},
			}
		);
	},

	getDownTime(trainerId: string, token: string) {
		return apiClient.post(
			`/slips/downtime/${trainerId}`,
			{},
			{
				headers: {
					'Content-Type': 'application/json',
					authorization: 'Basic ' + token,
				},
			}
		);
	},

	requestDownTime(
		trainerId: string,
		from: any,
		to: any,
		option: string | null,
		token: string
	) {
		return apiClient.post(
			`/slips/downtime/create/${trainerId}`,
			{
				datefrom: from,
				dateto: to,
				option: option,
			},
			{
				headers: {
					'Content-Type': 'application/json',
					authorization: 'Basic ' + token,
				},
			}
		);
	},

	removeDownTime(downtimeId: string, token: string) {
		return apiClient.post(
			`/slips/downtime/delete/${downtimeId}`,
			{},
			{
				headers: {
					'Content-Type': 'application/json',
					authorization: 'Basic ' + token,
				},
			}
		);
	},
};

export default trainerReportsAPI;
