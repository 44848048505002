/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, forwardRef } from 'react';
import MaterialTable, { Icons } from 'material-table';

import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import autoTableSetDefaults from 'jspdf-autotable';

import ReportingCenter from '../Assets/reporting-center-logo.png';
import WhiteLogo from '../Assets/White Logo.png';

import spartanBold from '../Assets/spartanBold.js';
import spartanRegular from '../Assets/spartanRegular.js';
import openSans from '../Assets/openSans.js';

import moment from 'moment';

import { makeStyles, Theme } from '@material-ui/core/styles';

import { CsvBuilder } from 'filefy';

import AddBox from '@material-ui/icons/AddBox';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Today from '@material-ui/icons/Today';
import { KeyboardArrowDown } from '@mui/icons-material';

const tableIcons: Icons = {
	Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
	Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
	Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
	DetailPanel: forwardRef((props, ref) => (
		<ChevronRight {...props} ref={ref} />
	)),
	Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
	Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
	Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
	FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
	LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
	NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	PreviousPage: forwardRef((props, ref) => (
		<ChevronLeft {...props} ref={ref} />
	)),
	ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
	SortArrow: forwardRef((props, ref) => (
		<KeyboardArrowDown style={{ color: '#ffffff' }} {...props} ref={ref} />
	)),
	ThirdStateCheck: forwardRef((props, ref) => (
		<Remove {...props} ref={ref} />
	)),
	ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const useStyles = makeStyles((theme: Theme) => ({
	tableContainer: {
		height: 725,
		display: 'flex',
		margin: '0px auto',
		justifyContent: 'flex-start',
		width: '95%',
		flexDirection: 'column',
		marginTop: '20px',
		[theme.breakpoints.down('lg')]: {
			height: 550,
		},
	},
	tableInnerContainer: {
		height: 675,
		display: 'flex',
		[theme.breakpoints.down('lg')]: {
			height: 550,
		},
	},
	tableLabel: {
		textDecoration: 'underline',
		textTransform: 'uppercase',
		fontFamily: `'League Spartan', sans-serif`,
		color: '#707070',
	},
	tableDate: {
		marginLeft: 10,
		fontFamily: `'League Spartan', sans-serif`,
		color: '#707070',
		textDecoration: 'underline',
		textTransform: 'uppercase',
	},
}));

function AdminSessionTable(props: any) {
	const [ticketData, setTicketData] = React.useState(
		props.ticketData.sort((a: any, b: any) => a.name.localeCompare(b.name))
	);

	const totalsData = props.totalsData;

	const classes = useStyles();

	const getTableData = (columnData: any, rowInfo: any) => {
		const columns = columnData

			.filter(
				(columnDef: any) =>
					(!columnDef.hidden || columnDef.export === true) &&
					columnDef.export !== false &&
					columnDef.field
			)
			.sort((a: any, b: any) =>
				a.tableData.columnOrder > b.tableData.columnOrder ? 1 : -1
			);

		const data = rowInfo.map((rowData: any) =>
			columns.map((columnDef: any) => getFieldValue(rowData, columnDef))
		);

		return [columns, data];
	};

	const byString = (o: any, s: any) => {
		if (!s) {
			return;
		}
		s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
		s = s.replace(/^\./, ''); // strip a leading dot
		var a = s.split('.');
		for (var i = 0, n = a.length; i < n; ++i) {
			var x = a[i];
			if (o && x in o) {
				o = o[x];
			} else {
				return;
			}
		}
		return o;
	};

	const getFieldValue = (rowData: any, columnDef: any, lookup = true) => {
		let value =
			typeof rowData[columnDef.field] !== 'undefined'
				? rowData[columnDef.field]
				: byString(rowData, columnDef.field);
		if (columnDef.lookup && lookup) {
			value = columnDef.lookup[value];
		}

		return value;
	};

	useEffect(() => {
		if (ticketData !== props.ticketData) {
			setTicketData(props.ticketData);
		}
	}, [props.ticketData, ticketData]);

	return (
		<div className={classes.tableContainer}>
			<div className={classes.tableInnerContainer}>
				<MaterialTable
					{...props}
					title={
						<React.Fragment>
							<span className={classes.tableLabel}>
								Completed Sessions Report:
								<span className={classes.tableDate}>
									{props.startDate}
								</span>
								{props.endDate !== props.startDate && (
									<span> - </span>
								)}
								{props.endDate !== props.startDate && (
									<span className={classes.tableLabel}>
										{props.endDate}
									</span>
								)}
							</span>
						</React.Fragment>
					}
					data={ticketData}
					columns={[
						{ title: 'Trainer', field: 'name', align: 'left' },
						{
							title: '# of Tickets',
							field: 'total',
							align: 'center',
						},
						{
							title: 'Total Minutes',
							field: 'minutes',
							align: 'center',
						},
						{
							title: 'Avg Time',
							field: 'average',
							align: 'center',
						},
					]}
					icons={tableIcons}
					style={{
						fontFamily:
							'roboto, "Open Sans", Helvetica, sans-serif',
						boxShadow: 'none',
						width: '100%',
						backgroundColor: '#efefef',
					}}
					options={{
						maxBodyHeight: window.innerHeight > 900 ? 600 : 475,
						sorting: false,
						draggable: false,
						actionsColumnIndex: -1,
						exportButton: true,
						exportFileName: 'Completed Sessions Report',
						search: true,
						showTitle: true,
						paging: false,
						headerStyle: {
							backgroundColor: '#000352',
							color: '#ffffff',
							borderRight: '2px solid #dadad9',
							textAlign: 'center',
							fontSize: '15px',
							fontWeight: 500,
							textTransform: 'uppercase',
							fontFamily: `'League Spartan', sans-serif`,
							maxHeight: '40px',
						},
						padding: 'dense',
						rowStyle: (rowData, index) => {
							if (index % 2) {
								return {
									backgroundColor: '#f3f3f3',
									padding: '0px 16px',
									height: 40,
								};
							} else {
								return { padding: '0px 16px', height: 40 };
							}
						},
						// @ts-ignore
						exportPdf: (columns1: any, data1: any) => {
							if (jsPDF !== null) {
								const [columns, data] = getTableData(
									columns1,
									data1
								);

								const [totalColumns, totalData] = getTableData(
									columns1,
									totalsData
								);
								const unit = 'px';
								const size = 'Ledger';
								const orientation = 'landscape';
								const doc = new jsPDF(orientation, unit, size);

								doc.addFileToVFS(
									'SpartanBold.ttf',
									spartanBold
								);
								doc.addFont(
									'SpartanBold.ttf',
									'SpartanBold',
									'normal'
								);
								doc.addFileToVFS('OpenSans.ttf', openSans);
								doc.addFont(
									'OpenSans.ttf',
									'OpenSans',
									'normal'
								);
								autoTableSetDefaults(doc, {
									headStyles: {
										fillColor: [0, 3, 82],
										font: 'SpartanBold',
									},
									bodyStyles: { font: 'OpenSans' },
								});

								const heroImageWidth =
									doc.internal.pageSize.getWidth();
								const reportingCenterX =
									doc.internal.pageSize.getWidth() - 125;
								const currentTime = moment(Date.now()).format(
									'MMMM DD, YYYY, hh:mm A'
								);

								doc.setFillColor('#000352');
								doc.rect(0, 0, heroImageWidth, 50, 'F');

								doc.addImage(WhiteLogo, 25, 10, 100, 33);
								doc.addImage(
									ReportingCenter,
									reportingCenterX,
									15,
									100,
									20
								);
								doc.setTextColor('#ffffff');
								doc.setFontSize(24);
								doc.text('Completed Session Report', 460, 35);
								doc.setFontSize(16);
								doc.addFileToVFS(
									'SpartanRegular.ttf',
									spartanRegular
								);
								doc.addFont(
									'SpartanRegular.ttf',
									'SpartanRegular',
									'normal'
								);
								doc.setFont('SpartanRegular');
								doc.text(currentTime, 460, 55);
								autoTable(doc, {
									startY: 125,
									head: [
										columns.map(
											(columnDef: { title: any }) =>
												columnDef.title
										),
									],
									body: data,
									columnStyles: {
										0: { halign: 'left' },
										1: { halign: 'center' },
										2: { halign: 'center' },
										3: { halign: 'center' },
									},
									willDrawCell: (data: {
										cell: {
											section: string;
											styles: { halign: string };
										};
										column: { index: number };
									}) => {
										if (
											data.cell.section === 'head' &&
											(data.column.index === 1 ||
												data.column.index === 2 ||
												data.column.index === 3)
										) {
											data.cell.styles.halign = 'center';
										}
									},
								});
								autoTable(doc, {
									startY:
										(doc as any).lastAutoTable.finalY + 20,
									body: totalData,
									columnStyles: {
										0: { halign: 'left' },
										1: { halign: 'center' },
										2: { halign: 'center' },
										3: { halign: 'center' },
									},
									willDrawCell: (data: {
										cell: {
											section: string;
											styles: { halign: string };
										};
										column: { index: number };
									}) => {
										if (
											data.cell.section === 'head' &&
											(data.column.index === 1 ||
												data.column.index === 2 ||
												data.column.index === 3)
										) {
											data.cell.styles.halign = 'center';
										}
									},
								});
								doc.save(
									'Completed Session Report - ' +
										moment(Date.now()).format(
											'ddd MMM DD hh:mm:ss YYYY'
										)
								);
							}
						},
						exportCsv: (columns1, data1) => {
							const [columns, data] = getTableData(
								columns1,
								data1
							);

							let fileName =
								'Completed Session Report - ' +
								moment(Date.now()).format(
									'ddd MMM DD hh:mm:ss YYYY'
								);

							const builder = new CsvBuilder(fileName + '.csv');
							builder
								.setDelimeter(props.exportDelimiter)
								.setColumns(
									columns.map(
										(columnDef: { title: any }) =>
											columnDef.title
									)
								)
								.addRows(data)
								.exportFile();
						},
					}}
					actions={[
						{
							icon: () => <Today />,
							tooltip: 'Change Date Range',
							onClick: (event, rowData) => {
								props.handleDatePickerToggle(
									event,
									'Completed Sessions - Admin'
								);
							},
							isFreeAction: true,
						},
					]}
				/>
			</div>
			<div>
				<MaterialTable
					data={props.totalsData}
					columns={[
						{ title: 'Total', field: 'name', align: 'center' },
						{
							title: '# of Tickets',
							field: 'total',
							align: 'center',
							cellStyle: {
								borderRight: '2px solid #ffffff',
								borderLeft: '2px solid #ffffff',
							},
						},
						{
							title: 'Total Minutes',
							field: 'minutes',
							align: 'center',
							cellStyle: {
								borderRight: '2px solid #ffffff',
							},
						},
						{
							title: 'Avg Time',
							field: 'average',
							align: 'center',
						},
					]}
					icons={tableIcons}
					style={{
						fontFamily:
							'roboto, "Open Sans", Helvetica, sans-serif',
						boxShadow: 'none',
						width: 'calc(100% - 17px)',
						backgroundColor: '#efefef',
					}}
					options={{
						sorting: false,
						draggable: false,
						actionsColumnIndex: -1,
						toolbar: false,
						header: false,
						search: false,
						showTitle: false,
						paging: false,
						padding: 'dense',
						rowStyle: {
							backgroundColor: '#d65200',
							color: '#ffffff',
							textTransform: 'uppercase',
							fontFamily: `'League Spartan', sans-serif`,
							fontWeight: 500,
							textAlign: 'center',
						},
					}}
				/>
			</div>
		</div>
	);
}

export default AdminSessionTable;
