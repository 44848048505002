import React from 'react';

import { usePromiseTracker } from 'react-promise-tracker';

import FadeLoader from 'react-spinners/FadeLoader';

import { makeStyles, Theme } from '@material-ui/core/styles';

import { Dialog } from '@material-ui/core';

import '../App.css';

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		display: 'flex',
		margin: '0 auto',
		justifyContent: 'center',
	},
	spinnerContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		margin: '0 auto',
		height: '100px',
		width: '150px',
	},
}));

interface ISpinnerProps {
	spinnerRef: any;
}

function Spinner(props: ISpinnerProps): JSX.Element {
	const classes = useStyles();
	const { promiseInProgress } = usePromiseTracker();

	return (
		<div className={classes.root}>
			{promiseInProgress === true ? (
				<Dialog open={promiseInProgress}>
					<div className={classes.spinnerContainer}>
						<FadeLoader ref={props.spinnerRef} color={'#000352'} />
					</div>
				</Dialog>
			) : null}
		</div>
	);
}

export default Spinner;
