/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { createRef, useEffect } from 'react';
import moment from 'moment';

import { IconButton } from '@mui/material';

import DownTimeTable from '../Components/DownTimeTable';
import CompletedTicketsTable from '../Components/CompletedTicketsTable';

import { DateRange } from '@mui/lab';

import { Today } from '@mui/icons-material';

import DispositionTable from '../Components/DispositionTable';
import CompletedSessionCard from '../Components/CompletedSessionCard';
import DownTimeRequests from '../Components/DownTimeRequests';
import DateSelector from '../Components/DatePicker';

import { makeStyles, Theme } from '@material-ui/core/styles';
import { trackPromise } from 'react-promise-tracker';
import trainerReportsAPI from '../API/trainerReportsAPI';

import { useCookies } from 'react-cookie';

import {
	SlipDispositions,
	CompletedSlip,
	SlipTotals,
	User,
	DownTimes,
} from '../@types/InterfaceDefinitions';

const useStyles = makeStyles((theme: Theme) => ({
	contentContainer: {
		display: 'flex',
		flexDirection: 'column',
		padding: '10px 0px',
		[theme.breakpoints.down('lg')]: {
			padding: '5px 0px',
		},
	},
	sectionTitle: {
		textDecoration: 'underline',
		textTransform: 'uppercase',
		fontFamily: `'League Spartan', sans-serif`,
		color: '#707070',
	},
	sectionDate: {
		marginLeft: 10,
		fontFamily: `'League Spartan', sans-serif`,
		color: '#707070',
		textDecoration: 'underline',
		textTransform: 'uppercase',
	},
	sectionEndDate: {
		fontFamily: `'League Spartan', sans-serif`,
		color: '#707070',
		textDecoration: 'underline',
		textTransform: 'uppercase',
	},
	sectionTitleContainer: {
		display: 'flex',
		marginBottom: '5px',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	noDateSectionTitleContainer: {
		display: 'flex',
		marginBottom: '5px',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: '10px 0px',
	},
	completedSessionRow: {
		width: '100%',
		display: 'flex',
		justifyContent: 'space-evenly',
		[theme.breakpoints.down('lg')]: {
			justifyContent: 'space-around',
		},
	},
	dispostionRow: {
		width: '100%',
		display: 'flex',
		justifyContent: 'space-evenly',
		marginTop: 5,
		height: 250,
		[theme.breakpoints.down('lg')]: {
			justifyContent: 'space-around',
			height: 250,
		},
	},
	downTimeLabelContainer: {
		display: 'flex',
		marginBottom: '5px',
		height: 40,
		alignItems: 'center',
	},
	completedTicketsRow: {
		height: 290,
		width: '100%',
		marginTop: 5,
		padding: '0px 73px',
		[theme.breakpoints.down('lg')]: {
			height: 235,
			padding: '0px 64px',
		},
	},
	dispositionContainer: {
		height: 200,
		width: 450,
		[theme.breakpoints.down('lg')]: {
			height: 150,
			width: 340,
		},
	},
	downTimeTableContainer: {
		height: 200,
		width: 1000,
		[theme.breakpoints.down('lg')]: {
			height: 200,
			width: 800,
		},
	},
	padding0: {
		padding: 0,
	},
}));

interface ITrainerReportsPanelProps {
	slipTotals: SlipTotals;
	slipDispositions: SlipDispositions;
	completedSlips: CompletedSlip;
	downTimeRequests: DownTimes;
	user: User;
	setCompletedSlips: (slips: CompletedSlip) => void;
	setSlipDispositions: (dispositions: SlipDispositions) => void;
	setSlipTotals: (totals: SlipTotals) => void;
	handleUpdateDowntime: (downTimeRequests: DownTimes) => void;
	getTrainerReportInfo: () => void;
}

function TrainerReportsPanel(props: ITrainerReportsPanelProps): JSX.Element {
	const [startDate, setStartDate] = React.useState(
		moment(Date.now()).format('MM/DD/YY')
	);
	const [endDate, setEndDate] = React.useState(
		moment(Date.now()).format('MM/DD/YY')
	);
	const [datePickerOpen, setDatePickerOpen] = React.useState(false);
	const [cookies] = useCookies(['token']);

	const token = cookies.token;

	const classes = useStyles();

	const downTimeTableRef = createRef();
	const completedTicketTableRef = createRef();

	const handleDateChange = async (startDate: number, endDate: number) => {
		setStartDate(moment(startDate).format('MM/DD/YY'));
		setEndDate(moment(endDate).format('MM/DD/YY'));
		try {
			const slipTotalsResponse = await trainerReportsAPI.getSlipTotals(
				props.user.id,
				startDate,
				endDate,
				token
			);
			const slipDispositionsResponse =
				await trainerReportsAPI.getSlipDispositions(
					props.user.id,
					startDate,
					endDate,
					token
				);
			const completedSlipsResponse = await trackPromise(
				trainerReportsAPI.getCompletedSlips(
					props.user.id,
					startDate,
					endDate,
					token
				)
			);
			props.setSlipTotals(slipTotalsResponse.data);
			props.setSlipDispositions(slipDispositionsResponse.data);
			props.setCompletedSlips(completedSlipsResponse.data);
		} catch (error) {
			console.error(error);
		}

		setDatePickerOpen(false);
	};

	const handleDatePickerToggle = () => {
		setDatePickerOpen(!datePickerOpen);
		setStartDate(moment(Date.now()).format('MM/DD/YY'));
		setEndDate(moment(Date.now()).format('MM/DD/YY'));
	};

	useEffect((): any => {
		const interval = setInterval(() => {
			props.getTrainerReportInfo();
			setStartDate(moment(Date.now()).format('MM/DD/YY'));
			setEndDate(moment(Date.now()).format('MM/DD/YY'));
		}, 300000);
		return () => {
			console.log('clearing interval');
			clearInterval(interval);
		};
	}, [props]);

	return (
		<div className={classes.contentContainer}>
			<DateSelector
				datePickerOpen={datePickerOpen}
				handleDatePickerToggle={handleDatePickerToggle}
				handleDateChange={handleDateChange}
				startDate={startDate}
				endDate={endDate}
				setStartDate={setStartDate}
				setEndDate={setEndDate}
			/>
			<div className={classes.completedSessionRow}>
				<div>
					<div className={classes.sectionTitleContainer}>
						<span className={classes.sectionTitle}>
							Completed Sesssions:
							<span className={classes.sectionDate}>
								{startDate}
							</span>
							{endDate !== startDate && <span> - </span>}
							{endDate !== startDate && (
								<span className={classes.sectionEndDate}>
									{endDate}
								</span>
							)}
						</span>
						<IconButton
							onClick={() => handleDatePickerToggle()}
							className={classes.padding0}>
							<Today />
						</IconButton>
					</div>
					<CompletedSessionCard slipTotals={props.slipTotals} />
				</div>
				<DownTimeRequests
					handleUpdateDowntime={props.handleUpdateDowntime}
					user={props.user}
					downTimeRequests={props.downTimeRequests}
				/>
			</div>
			<div className={classes.dispostionRow}>
				<div className={classes.dispositionContainer}>
					<div className={classes.noDateSectionTitleContainer}>
						<span className={classes.sectionTitle}>
							Dispositions:
							<span className={classes.sectionDate}>
								{startDate}
							</span>
							{endDate !== startDate && <span> - </span>}
							{endDate !== startDate && (
								<span className={classes.sectionEndDate}>
									{endDate}
								</span>
							)}
						</span>
						{/* <IconButton
							onClick={() => handleDatePickerToggle()}
							className={classes.padding0}>
							<Today />
						</IconButton> */}
					</div>
					<DispositionTable
						slipDispositions={props.slipDispositions}
					/>
				</div>
				<div className={classes.downTimeTableContainer}>
					<div className={classes.downTimeLabelContainer}>
						<span className={classes.sectionTitle}>
							Downtime Report:
						</span>
						<span className={classes.sectionDate}>
							{moment(Date.now()).format('MM/DD/YY')}
						</span>
					</div>
					<DownTimeTable
						{...props}
						handleUpdateDowntime={props.handleUpdateDowntime}
						downTimeRequests={props.downTimeRequests}
						tableRef={downTimeTableRef}
					/>
				</div>
			</div>
			<div className={classes.completedTicketsRow}>
				<div className={classes.noDateSectionTitleContainer}>
					<span className={classes.sectionTitle}>
						Completed Tickets:
						<span className={classes.sectionDate}>{startDate}</span>
						{endDate !== startDate && <span> - </span>}
						{endDate !== startDate && (
							<span className={classes.sectionEndDate}>
								{endDate}
							</span>
						)}
					</span>
					{/* <IconButton
						onClick={() => handleDatePickerToggle()}
						className={classes.padding0}>
						<Today />
					</IconButton> */}
				</div>
				<CompletedTicketsTable
					{...props}
					tableRef={completedTicketTableRef}
					completedSlips={props.completedSlips}
				/>
			</div>
		</div>
	);
}

export default TrainerReportsPanel;
