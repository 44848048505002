import React, { createRef } from 'react';

import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import AdapterMoment from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import Spinner from './Components/spinner';
import HomePage from './Pages/Home';

import { withCookies } from 'react-cookie';

import '@fontsource/league-spartan/700.css';
import './App.css';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import LoginPage from './Pages/Login';

import { User } from './@types/InterfaceDefinitions';

const useStyles = makeStyles((theme) => ({
	root: {
		textAlign: 'center',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		fontFamily: 'roboto, "Open Sans", Helvetica, sans-serif',
		margin: '0 auto',
		height: '100vh',
	},
}));

function App() {
	const spinnerRef: React.RefObject<unknown> = createRef();
	const classes = useStyles();
	const [isUserLoggedIn, setIsUserLoggedIn] = React.useState(false);
	const [user, setUser] = React.useState(null as User | null);

	const checkUserLogin = (value: boolean) => {
		setIsUserLoggedIn(value);
	};

	const getUser = (user: User) => {
		setUser(user);
	};

	return (
		<LocalizationProvider dateAdapter={AdapterMoment}>
			<Router>
				<Paper className={classes.root}>
					<Spinner spinnerRef={spinnerRef} />
					<Switch>
						<Route
							exact
							path='/'
							render={(props) => (
								<HomePage
									{...props}
									isUserLoggedIn={isUserLoggedIn}
									user={user}
									getUser={getUser}
									handleUserLoggedIn={checkUserLogin}
								/>
							)}
						/>
						<Route
							exact
							path='/login'
							component={(props: any) => (
								<LoginPage
									{...props}
									handleUserLoggedIn={checkUserLogin}
									getUser={getUser}
								/>
							)}
						/>
					</Switch>
				</Paper>
			</Router>
		</LocalizationProvider>
	);
}

const newApp = withCookies(App);

export default newApp;
