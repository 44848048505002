import apiClient from './apiClient';

const adminReportsAPI = {
	getAllSlips(token: string) {
		return apiClient.get('/slips/getall', {
			headers: {
				'Content-Type': 'application/json',
				authorization: 'Basic ' + token,
			},
		});
	},

	getCompletedSlips(from: any, to: any, token: string) {
		return apiClient.post(
			'/slips/completed/all',
			{
				datefrom: from,
				dateto: to,
			},
			{
				headers: {
					'Content-Type': 'application/json',
					authorization: 'Basic ' + token,
				},
			}
		);
	},

	getSlipDispositions(from: any, to: any, token: string) {
		return apiClient.post(
			'/slips/dispositions/all',
			{
				datefrom: from,
				dateto: to,
			},
			{
				headers: {
					'Content-Type': 'application/json',
					authorization: 'Basic ' + token,
				},
			}
		);
	},

	getNextAvailableSlip(token: string) {
		return apiClient.get('/slips/nextavailable', {
			headers: {
				'Content-Type': 'application/json',
				authorization: 'Basic ' + token,
			},
		});
	},

	dispositionSlip(
		slip: any,
		trainerId: string,
		action: string,
		options: any,
		token: string
	) {
		return apiClient.post(
			'/slips/complete',
			{
				slip: slip,
				trainerId: trainerId,
				action: action,
				options: options,
			},
			{
				headers: {
					'Content-Type': 'application/json',
					authorization: 'Basic ' + token,
				},
			}
		);
	},

	reserveSlip(slip: any, trainerId: string, token: string) {
		return apiClient.post(`/slips/reserve/${trainerId}`, slip, {
			headers: {
				'Content-Type': 'application/json',
				authorization: 'Basic ' + token,
			},
		});
	},
};

export default adminReportsAPI;
