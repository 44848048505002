import React from 'react';

import { List, ListItemIcon, ListItemText } from '@material-ui/core';

import Collapse from '@mui/material/Collapse';
import ListItemButton from '@mui/material/ListItemButton';

import {
	ExpandMore,
	ExpandLess,
	SettingsApplications,
	Receipt,
	AssignmentTurnedIn,
	FactCheck,
} from '@mui/icons-material';

import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
	list: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		marginTop: '20px',
	},
	listItem: {
		'& span': {
			letterSpacing: 'normal',
			fontSize: '14px',
			textTransform: 'uppercase',
		},
		[theme.breakpoints.down('lg')]: {
			'& span': {
				fontSize: '12px',
			},
		},
	},
	listItemIcon: {
		color: '#ffffff',
		marginLeft: '26px',
		[theme.breakpoints.down('lg')]: {
			marginLeft: '10px',
		},
	},
	navListItem: {
		paddingBottom: '5px',
		marginBottom: '10px',
		paddingTop: '3px',
	},
}));

function AdminNavList(props: any) {
	const classes = useStyles();
	return (
		<React.Fragment>
			<ListItemButton onClick={props.handleAdminNavClick}>
				<ListItemIcon className={classes.listItemIcon}>
					<SettingsApplications />
				</ListItemIcon>
				<ListItemText className={classes.listItem} primary='Admin' />
				{props.adminNavOpen ? <ExpandLess /> : <ExpandMore />}
			</ListItemButton>
			<Collapse in={props.adminNavOpen} timeout='auto' unmountOnExit>
				<List>
					<ListItemButton
						sx={{
							pl: 4,
							backgroundColor:
								props.selectedDrawerButton === 3
									? '#707070'
									: 'transparent',
						}}
						onClick={() => {
							props.handleDrawerNavClick(3);
						}}>
						<ListItemIcon className={classes.listItemIcon}>
							<Receipt />
						</ListItemIcon>
						<ListItemText
							className={classes.listItem}
							primary='All Slips'
						/>
					</ListItemButton>
					<ListItemButton
						sx={{
							pl: 4,
							backgroundColor:
								props.selectedDrawerButton === 4
									? '#707070'
									: 'transparent',
						}}
						onClick={() => {
							props.handleDrawerNavClick(4);
						}}>
						<ListItemIcon className={classes.listItemIcon}>
							<AssignmentTurnedIn />
						</ListItemIcon>
						<ListItemText
							className={classes.listItem}
							primary='Sessions'
						/>
					</ListItemButton>
					<ListItemButton
						sx={{
							pl: 4,
							backgroundColor:
								props.selectedDrawerButton === 5
									? '#707070'
									: 'transparent',
						}}
						onClick={() => {
							props.handleDrawerNavClick(5);
						}}>
						<ListItemIcon className={classes.listItemIcon}>
							<FactCheck />
						</ListItemIcon>
						<ListItemText
							className={classes.listItem}
							primary='Dispositions'
						/>
					</ListItemButton>
				</List>
			</Collapse>
		</React.Fragment>
	);
}

export default AdminNavList;
