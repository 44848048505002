import React from 'react';
import { Dialog, DialogContent } from '@mui/material';

import SlipModalButtons from './SlipModalButtons';
import SlipModalData from './SlipModalData';
import SlipModalRadios from './SlipModalRadios';
import { Slip, User } from '../@types/InterfaceDefinitions';

import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
	slipModalContainer: {
		display: 'flex',
		flexDirection: 'column',
		marginTop: '20px',
	},
	slipModalTitle: {
		textDecoration: 'underline',
		textTransform: 'uppercase',
		fontFamily: `'League Spartan', sans-serif`,
		color: '#707070',
		marginBottom: '10px',
	},
	dialogContent: {
		maxWidth: '800px !important',
	},
}));

interface ISlipModalProps {
	slipModalOpen: boolean;
	selectedSlip: Slip | null;
	handleSlipModalToggle: (selectedSlip: Slip | null) => void;
	handleFormatPhone: (phone: string) => string | undefined;
	setSlipModalOpen: (slipModalOpen: boolean) => void;
	user: User;
	handleUpdateAdminSlips: any;
}

function SlipModal(props: ISlipModalProps) {
	const [dispositionOption, setDispositionOption] =
		React.useState<string>('');

	const handleDispositionChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setDispositionOption(event.target.value);
	};

	const classes = useStyles();

	return (
		<Dialog maxWidth='md' open={props.slipModalOpen} keepMounted>
			<DialogContent className={classes.dialogContent}>
				<span className={classes.slipModalTitle}>Selected Ticket:</span>
				{props.selectedSlip && (
					<div className={classes.slipModalContainer}>
						<SlipModalData
							selectedSlip={props.selectedSlip}
							handleFormatPhone={props.handleFormatPhone}
						/>
						<SlipModalRadios
							dispositionOption={dispositionOption}
							handleDispositionChange={handleDispositionChange}
						/>
						<SlipModalButtons
							selectedSlip={props.selectedSlip}
							handleSlipModalToggle={props.handleSlipModalToggle}
							user={props.user}
							dispositionOption={dispositionOption}
							setDispositionOption={setDispositionOption}
							setSlipModalOpen={props.setSlipModalOpen}
							handleUpdateAdminSlips={
								props.handleUpdateAdminSlips
							}
						/>
					</div>
				)}
			</DialogContent>
		</Dialog>
	);
}

export default SlipModal;
