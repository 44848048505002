import React from 'react';
import { Icon, Tooltip } from '@mui/material';

import moment from 'moment';

import { MoreHoriz } from '@mui/icons-material';

import { Slip } from '../@types/InterfaceDefinitions';

import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
	label: {
		textDecoration: 'underline',
		textTransform: 'uppercase',
		fontFamily: `'League Spartan', sans-serif`,
		color: '#707070',
	},
	flexColumn: {
		display: 'flex',
		flexDirection: 'column',
	},
	marginBottom: {
		marginBottom: 10,
	},
	marginTopBottom: {
		margin: '10px 0px',
	},
	marginLeft: {
		marginLeft: 10,
	},
	spaceBetween: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	flexCenter: {
		display: 'flex',
		alignItems: 'center',
	},
}));

interface ISlipModalDataProps {
	selectedSlip: Slip;
	handleFormatPhone: (phone: string) => string | undefined;
}

function SlipModalData(props: ISlipModalDataProps) {
	const classes = useStyles();
	return (
		<div className={classes.spaceBetween}>
			<div className={classes.flexColumn}>
				<div>
					<span className={classes.label}>Name:</span>{' '}
					{props.selectedSlip.firstName} {props.selectedSlip.lastName}
				</div>
				<div className={classes.marginTopBottom}>
					<span className={classes.label}>Call Ready:</span>{' '}
					{moment(props.selectedSlip.callDate).format(
						'MM/DD/YYYY hh:mm A'
					)}
				</div>
				<div className={classes.marginBottom}>
					<span className={classes.label}>Call Back Number:</span>{' '}
					{props.handleFormatPhone(props.selectedSlip.callBackNumber)}
				</div>
				<div>
					<span className={classes.label}>Last Action Code:</span>{' '}
					{props.selectedSlip.actionCode}
				</div>
			</div>
			<div className={classes.flexColumn}>
				<div>
					<span className={classes.label}>Dealership:</span>{' '}
					{props.selectedSlip.dealership}
				</div>
				<div className={classes.marginTopBottom}>
					<span className={classes.label}>Dealer Phone:</span>{' '}
					{props.handleFormatPhone(props.selectedSlip.dealerPhone)}
				</div>
				<div className={classes.marginBottom}>
					<span className={classes.label}>Return Caller Code:</span>{' '}
					{props.selectedSlip.returnCallerCode}
				</div>
				<div className={classes.flexCenter}>
					<span className={classes.label}>Internal Notes:</span>
					<Tooltip title={props.selectedSlip.internalNotes}>
						<Icon className={classes.marginLeft}>
							<MoreHoriz />
						</Icon>
					</Tooltip>
				</div>
			</div>
		</div>
	);
}

export default SlipModalData;
