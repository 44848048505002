import React, { forwardRef } from 'react';
import MaterialTable, { Icons } from 'material-table';

import AddBox from '@material-ui/icons/AddBox';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

import { KeyboardArrowDown } from '@mui/icons-material';

import moment from 'moment';

const tableIcons: Icons = {
	Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
	Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
	Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
	DetailPanel: forwardRef((props, ref) => (
		<ChevronRight {...props} ref={ref} />
	)),
	Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
	Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
	Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
	FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
	LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
	NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	PreviousPage: forwardRef((props, ref) => (
		<ChevronLeft {...props} ref={ref} />
	)),
	ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
	SortArrow: forwardRef((props, ref) => (
		<KeyboardArrowDown
			style={{ color: '#ffffff', fontSize: '1rem', marginTop: 4 }}
			{...props}
			ref={ref}
		/>
	)),
	ThirdStateCheck: forwardRef((props, ref) => (
		<Remove {...props} ref={ref} />
	)),
	ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

interface ICompletedTicketsTableProps {
	tableRef: any;
	completedSlips: any;
}

function CompletedTicketsTable(
	props: ICompletedTicketsTableProps
): JSX.Element {
	return (
		<React.Fragment>
			<MaterialTable
				tableRef={props.tableRef}
				icons={tableIcons}
				columns={[
					{
						title: 'Name',
						field: 'name',
						cellStyle: {
							width: 500,
						},
						render: (rowData: any) => {
							return (
								<div>
									<div>
										{rowData.last_name},{' '}
										{rowData.first_name}
									</div>
								</div>
							);
						},
					},
					{
						title: 'Dealership',
						field: 'dealership',
						cellStyle: {
							width: 500,
						},
					},
					{
						title: 'Call Date',
						field: 'call_date',
						cellStyle: {
							width: 300,
						},
						render: (rowData: any) => {
							return (
								<div>
									<div>
										{moment(rowData.call_date).format(
											'MM/DD/YY HH:mm A'
										)}
									</div>
								</div>
							);
						},
					},
					{
						title: 'Completed Date',
						field: 'completedon',
						cellStyle: {
							width: 300,
						},
						render: (rowData: any) => {
							return (
								<div>
									<div>
										{moment(rowData.completedon).format(
											'MM/DD/YY HH:mm A'
										)}
									</div>
								</div>
							);
						},
					},
					{
						title: 'Initialized Date',
						field: 'markedon',
						cellStyle: {
							width: 300,
						},
						render: (rowData: any) => {
							return (
								<div>
									<div>
										{moment(rowData.markedon).format(
											'MM/DD/YY HH:mm A'
										)}
									</div>
								</div>
							);
						},
					},
					{
						title: 'Comments',
						field: 'comments',
						cellStyle: {
							width: 100,
						},
					},
					{
						title: 'Disposition',
						field: 'final_status',
						cellStyle: {
							width: 50,
						},
					},
				]}
				data={props.completedSlips}
				style={{
					fontFamily: 'roboto, "Open Sans", Helvetica, sans-serif',
				}}
				options={{
					draggable: false,
					thirdSortClick: false,
					actionsColumnIndex: -1,
					search: false,
					showTitle: false,
					paging: false,
					toolbar: false,
					headerStyle: {
						backgroundColor: '#000352',
						color: '#ffffff',
						borderRight: '2px solid #dadad9',
						textAlign: 'center',
						fontSize: '15px',
						fontWeight: 500,
						textTransform: 'uppercase',
						fontFamily: `'League Spartan', sans-serif`,
						padding: '0px',
						maxHeight: '40px',
						alignItems: 'center',
						alignSelf: 'center',
						alignContent: 'center',
					},
					padding: 'dense',
					rowStyle: (rowData, index) => {
						if (index % 2) {
							return {
								backgroundColor: '#f3f3f3',
								padding: '0px 16px',
								height: 40,
							};
						} else {
							return { padding: '0px 16px', height: 40 };
						}
					},
					maxBodyHeight: window.innerHeight > 900 ? 260 : 250,
				}}
			/>
		</React.Fragment>
	);
}

export default CompletedTicketsTable;
