import React from 'react';
import moment from 'moment';

import {useState,useEffect} from 'react'
import axios from 'axios'

import { makeStyles, Theme } from '@material-ui/core/styles';

import { Card, CardContent, Divider, Button } from '@material-ui/core';

import CCIcon from '../Assets/headset-mic.png';

import QTYIcon from '../Assets/QTYIcon.png';
import SlipMgmtIcon from '../Assets/SlipMgmtIcon.png';
import NewSlipsIcon from '../Assets/NewSlipsIcon.png';
import MobileIcon from '../Assets/MobileIcon.png';
import CallBackIcon from '../Assets/Callback.png';


// import { useCookies } from 'react-cookie';

const useStyles = makeStyles((theme: Theme) => ({
	salesForce: {
		height: '130px',
		width: '130px',
		padding: '10px',
		border: '1px solid #dadad9',
		boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.15)',
		borderRadius: '2px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		[theme.breakpoints.down('lg')]: {
			height: '100px',
			width: '100px',
		},
	},
	appTitle: {
		color: '#000352',
		fontWeight: 'bolder',
		fontSize: '20px',
		letterSpacing: 'normal',
		fontFamily: `'League Spartan', sans-serif`,
		textTransform: 'none',
		textDecoration: 'none',
	},
	tabTitle: {
		fontWeight: 'bold',
		fontSize: '26px',
		letterSpacing: 'normal',
		fontFamily: `'League Spartan', sans-serif`,
		textTransform: 'uppercase',
	},
	orangeCircle: {
		height: '80px',
		width: '80px',
		borderRadius: '50%',
		border: '3px solid #d65200',
		position: 'absolute',
		right: '378px',
		top: '172px',
		[theme.breakpoints.down('lg')]: {
			top: '165px',
			right: '318px',
		},
	},
	whiteCircle: {
		height: '130px',
		width: '130px',
		borderRadius: '50%',
		border: '3px solid #ffffff',
		opacity: '0.3',
		position: 'absolute',
		top: '203px',
		right: '401px',
		[theme.breakpoints.down('lg')]: {
			top: '200px',
			right: '339px',
		},
	},
	appCard: {
		height: '705px',
		width: '1540px',
		marginTop: '31px',
		[theme.breakpoints.down('lg')]: {
			height: '550px',
			width: '1250px',
		},
		[theme.breakpoints.between('md', 'lg')]: {
			width: '1250px',
		},
	},
	cardHeader: {
		width: '100%',
		backgroundColor: '#000352',
		color: '#ffffff',
		height: '110px',
		boxShadow: '0px 7px 7px rgba(0, 0, 0, 0.1)',
		[theme.breakpoints.down('lg')]: {
			height: '100px',
		},
	},
	cardHeaderContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		paddingTop: '15px',
	},
	cardHeaderNameContainer: {
		display: 'flex',
		flexDirection: 'column',
		textAlign: 'left',
		marginLeft: '49px',
		[theme.breakpoints.down('lg')]: {
			marginLeft: '55px',
		},
	},
	cardHeaderUserName: {
		fontSize: '32px',
		fontWeight: 'bold',
		textTransform: 'uppercase',
		[theme.breakpoints.down('lg')]: {
			fontSize: '28px',
		},
	},
	cardHeaderCTA: {
		display: 'flex',
		flexDirection: 'column',
		textAlign: 'left',
		marginRight: '80px',
		marginTop: '-6px',
		letterSpacing: 'normal',
		[theme.breakpoints.down('lg')]: {
			marginRight: '55px',
		},
	},
	cardHeaderCTAText: {
		fontSize: '24px',
		fontWeight: 'bold',
		fontFamily: `'League Spartan', sans-serif`,
		zIndex: 2,
		color: 'rgba(255, 255, 255, 0.8)',
		[theme.breakpoints.down('lg')]: {
			fontSize: '18px',
		},
	},
	cardHeaderWelcomeText: {
		fontSize: '26px',
		marginTop: '-7px',
		fontFamily: `'League Spartan', sans-serif`,
		color: 'rgba(255, 255, 255, 0.8)',
		[theme.breakpoints.down('lg')]: {
			fontSize: '20px',
		},
	},
	cardHeaderDateText: {
		fontSize: '22px',
		zIndex: 2,
	},
	cardContent: {
		padding: '0px',
		marginTop: '37px',
	},
	appLinkContainer: {
		display: 'flex',
		marginTop: '40px',
	},
	appLinkTitleContainer: {
		display: 'flex',
		flexDirection: 'column',
		marginLeft: '10px',
		textAlign: 'left',
		letterSpacing: 'normal',
	},
	testCallsLinkTitleContainer: {
		display: 'flex',
		flexDirection: 'column',
		marginLeft: '15px',
		textAlign: 'left',
		letterSpacing: 'normal',
	},
	sfLinkTitleContainer: {
		display: 'flex',
		flexDirection: 'column',
		marginLeft: '22px',
		textAlign: 'left',
		letterSpacing: 'normal',
	},
	salesForceLink: {
		marginLeft: '42px',
		textDecoration: 'none',
	},
	reportingCenterLink: {
		marginLeft: '10px',
		textDecoration: 'none',
	},
	testCallsLink: {
		marginLeft: '65px',
		textDecoration: 'none',
		[theme.breakpoints.down('lg')]: {
			marginLeft: '66px',
		},
	},
	enrollmentLink: {
		marginLeft: '62px',
		textDecoration: 'none',
		[theme.breakpoints.down('lg')]: {
			marginLeft: '64px',
		},
	},
	enrollmentLinkImg: {
		height: '57px',
	},
	acWebsiteLink: {
		marginLeft: '80px',
		textDecoration: 'none',
		[theme.breakpoints.down('lg')]: {
			marginLeft: '40px',
		},
	},
	tabTitleContainer: {
		display: 'flex',
		flexDirection: 'column',
		marginLeft: '50px',
		textAlign: 'left',
		lineHeight: 'normal',
		[theme.breakpoints.down('lg')]: {
			marginLeft: '50px',
		},
	},
	appLinkImg: {
		height: '75px',
		[theme.breakpoints.down('lg')]: {
			height: '50px',
		},
	},
	reportingCenterLinkImg: {
		width: '105px',
		height: '43px',
	},
	flexCenter: {
		display: 'flex',
		justifyContent: 'center',
	},
	flexStart: {
		display: 'flex',
		justifyContent: 'flex-start',
	},
	appSubtitle: {
		textTransform: 'none',
		color: '#3b3b3b',
	},

	greyText: {
		fontWeight: 'bold',
		color: 'rgba(37, 40, 50, 0.5)',
		fontSize: 18,
	},
	blueText: {
		fontWeight: 'bold',
		color: '#252832',
		fontSize: 18,
	},
}));

function ApplicationPanel(props: any): JSX.Element {

	var mobileLink = `${process.env.REACT_APP_MOBILE_REMOTE_URL}`;
	var newSlipLink = `${process.env.REACT_APP_NEW_TICKET_REMOTE_URL}`;
	var callBackLink = `${process.env.REACT_APP_CALLBACK_REMOTE_URL}`;

	// const mobileRemoteLink = `${process.env.REACT_APP_MOBILE_REMOTE_URL}`;
	// const newSlipRemoteLink = `${process.env.REACT_APP_NEW_TICKET_REMOTE_URL}`;

	const classes = useStyles();

	const [ip, setIP] = useState('');

	const getData = async () => {
		const res = await axios.get('https://geolocation-db.com/json/')
		console.log(res.data.IPv4);
		setIP(res.data.IPv4);

		if (res.data.IPv4=="71.46.235.114") {

			mobileLink = `${process.env.REACT_APP_MOBILE_LOCAL_URL}`;
			newSlipLink = `${process.env.REACT_APP_NEW_TICKET_LOCAL_URL}`;

		}
	  }

	  getData();


	// const os = require('os');

	// const networkInterfaces = os.networkInterfaces();
	// const ip2 = networkInterfaces['eth0'][0]['address']

	// console.log(networkInterfaces);
	// console.log(ip2);


	// const [cookies] = useCookies(['token']);

	// const token = cookies.token;

	// const coachingConsoleLink = `${process.env.REACT_APP_CC_URL}?${token}`;
	const coachingConsoleLink = `${process.env.REACT_APP_CC_URL}`;
	// const testCallsLink = `${process.env.REACT_APP_TEST_CALLS_URL}?${token}`;

	const qltyLink = `${process.env.REACT_APP_QUALITY_URL}`;
	const oldSlipLink = `${process.env.REACT_APP_OLD_SLIPS_URL}`;



	return (
		<Card className={classes.appCard}>
			<div className={classes.cardHeader}>
				<div className={classes.cardHeaderContainer}>
					<span className={classes.cardHeaderNameContainer}>
						<span className={classes.cardHeaderUserName}>
							Welcome back!
						</span>
					</span>
					<span className={classes.cardHeaderCTA}>
						<span className={classes.cardHeaderCTAText}>
							Today is the most productive day of your week
						</span>
						<span className={classes.cardHeaderDateText}>
							{moment().format('MMMM D, YYYY')}
						</span>
					</span>
				</div>
				<div className={classes.orangeCircle}></div>
				<div className={classes.whiteCircle}></div>
			</div>
			<Divider />
			<CardContent className={classes.cardContent}>
				<div className={classes.flexStart}>
					<span className={classes.tabTitleContainer}>
						<span className={classes.tabTitle}>Applications</span>
						<span>
							<span className={classes.blueText}>Find your</span>{' '}
							<span className={classes.greyText}>
								resources here
							</span>
						</span>
					</span>
				</div>



				<div className={classes.appLinkContainer}>
					<a  target="_BLANK" 
						className={classes.salesForceLink}
						href={coachingConsoleLink}
						rel='noopener noreferrer'>
						<Button>
							<div className={classes.salesForce}>
								<img
									className={classes.appLinkImg}
									src={CCIcon}
									alt='Icon with phone and message bubbles'
								/>
							</div>
							<span className={classes.sfLinkTitleContainer}>
								<span className={classes.appTitle}>
									Coaching Console
								</span>
								<span className={classes.appSubtitle}>
									Training Platform
								</span>
							</span>
						</Button>
					</a>
					<a  target="_BLANK" 
						className={classes.salesForceLink}
						href={newSlipLink}
						rel='noopener noreferrer'>
						<Button>
							<div className={classes.salesForce}>
								<img
									className={classes.appLinkImg}
									src={NewSlipsIcon}
									alt='Icon with phone and message bubbles'
								/>
							</div>
							<span className={classes.sfLinkTitleContainer}>
								<span className={classes.appTitle}>
									New Slips
								</span>
								<span className={classes.appSubtitle}>
									Operations Support
								</span>
							</span>
						</Button>
					</a>
					<a  target="_BLANK" 
						className={classes.salesForceLink}
						href={oldSlipLink}
						rel='noopener noreferrer'>
						<Button>
							<div className={classes.salesForce}>
								<img
									className={classes.appLinkImg}
									src={SlipMgmtIcon}
									alt='Icon with phone and message bubbles'
								/>
							</div>
							<span className={classes.sfLinkTitleContainer}>
								<span className={classes.appTitle}>
									Old Slip System
								</span>
								<span className={classes.appSubtitle}>
									Training Support
								</span>
							</span>
						</Button>
					</a>
				</div>


				<div className={classes.appLinkContainer}>

				<a  target="_BLANK" 
						className={classes.salesForceLink}
						href={qltyLink}
						rel='noopener noreferrer'>
						<Button>
							<div className={classes.salesForce}>
								<img
									className={classes.appLinkImg}
									src={QTYIcon}
									alt='Icon with phone and message bubbles'
								/>
							</div>
							<span className={classes.sfLinkTitleContainer}>
								<span className={classes.appTitle}>
									Quality Control
								</span>
								<span className={classes.appSubtitle}>
									Training Support
								</span>
							</span>
						</Button>
					</a>

					<a  target="_BLANK" 
						className={classes.salesForceLink}
						href={mobileLink}
						rel='noopener noreferrer'>
						<Button>
							<div className={classes.salesForce}>
								<img
									className={classes.appLinkImg}
									src={MobileIcon}
									alt='Icon with phone and message bubbles'
								/>
							</div>
							<span className={classes.sfLinkTitleContainer}>
								<span className={classes.appTitle}>
									Mobile Registrations
								</span>
								<span className={classes.appSubtitle}>
									Operations Support
								</span>
							</span>
						</Button>
					</a>


					<a  target="_BLANK" 
						className={classes.salesForceLink}
						href={callBackLink}
						rel='noopener noreferrer'>
						<Button>
							<div className={classes.salesForce}>
								<img
									className={classes.appLinkImg}
									src={CallBackIcon}
									alt='Icon with phone and message bubbles'
								/>
							</div>
							<span className={classes.sfLinkTitleContainer}>
								<span className={classes.appTitle}>
									CallBack Time
								</span>
								<span className={classes.appSubtitle}>
								    Operations Reports
								</span>
							</span>
						</Button>
					</a>


					


				</div>



			</CardContent>
		</Card>
	);
}

export default ApplicationPanel;
