import React from 'react';

import { Card } from '@mui/material';

import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
	card: {
		height: 100,
		width: 450,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		[theme.breakpoints.down('lg')]: {
			width: 350,
			height: 100,
		},
	},
	cardContent: {
		display: 'flex',
		justifyContent: 'space-evenly',
		marginBottom: 20,
	},
	totalContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
	},
	totalLabel: {
		color: '#707070',
		fontSize: 22,
		textDecoration: 'underline',
		[theme.breakpoints.down('lg')]: {
			fontSize: 18,
		},
	},
	totalValue: {
		color: '#707070',
		fontSize: 24,
		fontWeight: 'bold',
		[theme.breakpoints.down('lg')]: {
			fontSize: 20,
		},
	},
	avgTime: {
		color: '#d65200',
		fontSize: 24,
		fontWeight: 'bold',
		[theme.breakpoints.down('lg')]: {
			fontSize: 20,
		},
	},
}));

interface ICompletedSessionCardProps {
	slipTotals: any;
}

function CompletedSessionCard(props: ICompletedSessionCardProps) {
	const classes = useStyles();

	return (
		<Card className={classes.card}>
			<div className={classes.cardContent}>
				<div className={classes.totalContainer}>
					<span className={classes.totalLabel}>Tickets</span>
					<span className={classes.totalValue}>
						{props.slipTotals.total}
					</span>
				</div>
				<div className={classes.totalContainer}>
					<span className={classes.totalLabel}>Minutes</span>
					<span className={classes.totalValue}>
						{props.slipTotals.minutes
							? props.slipTotals.minutes
							: 0}
					</span>
				</div>

				<div className={classes.totalContainer}>
					<span className={classes.totalLabel}>Avg. Time:</span>
					<span className={classes.avgTime}>
						{props.slipTotals.average
							? props.slipTotals.average
							: '0'}
					</span>
				</div>

			</div>
		</Card>
	);
}

export default CompletedSessionCard;
