import React from 'react';

import NavigationDrawer from '../Components/NavigationDrawer';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: '#ffffff',
		height: '100vh',
	},
}));

function HomePage(props: any) {
	const classes = useStyles();
	return (
		<div className={classes.root}>
			<NavigationDrawer {...props} />
		</div>
	);
}

export default HomePage;
