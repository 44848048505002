import React from 'react';

import { Autocomplete, Card, TextField } from '@mui/material';

import { Button } from '@material-ui/core';

import { makeStyles, Theme } from '@material-ui/core/styles';

import moment from 'moment';

// import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import { TimePicker } from '@mui/lab';
// import AdapterMoment from '@mui/lab/AdapterMoment';
import { trackPromise } from 'react-promise-tracker';
import trainerReportsAPI from '../API/trainerReportsAPI';
import { useCookies } from 'react-cookie';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';

import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';



const useStyles = makeStyles((theme: Theme) => ({
	card: {
		height: 100,
		width: 1000,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		[theme.breakpoints.down('lg')]: {
			width: 800,
			height: 100,
		},
	},
	cardLabelContainer: {
		display: 'flex',
		marginBottom: '5px',
		height: 40,
		alignItems: 'center',
	},
	cardLabel: {
		textDecoration: 'underline',
		textTransform: 'uppercase',
		fontFamily: `'League Spartan', sans-serif`,
		color: '#707070',
	},
	buttonContainer: {
		width: 259,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		paddingLeft: 50,
	},
	optionContainer: {
		display: 'flex',
		justifyContent: 'space-evenly',
		marginTop: 20,
	},
	autocomplete: {
		display: 'flex',
		alignItems: 'center',
		marginRight: 30,
	},
	flexEvenly: {
		display: 'flex',
		justifyContent: 'space-evenly',
	},
	flexCenter: {
		display: 'flex',
		alignItems: 'center',
	},
	marginRight: {
		marginRight: '10px',
	},
	red: {
		color: 'red',
		marginLeft: 5,
	},
}));

const options = [
	'Benefits or Payroll meetings/questions',
	'Front Desk Coverage',
	'IT Support',
	'My internet service was down',
	'No sessions available',
	'PA Coverage',
	'Personal time off',
	'Prepping time',
	'Pulling messages',
	'Session Grade Dispute',
	'Session Grading',
	'Team/Company Meeting',
	'Testing Time',
	'Weekly Meeting with TL'
];

function DownTimeRequests(props: any) {
	const [startTime, setStartTime] = React.useState(new Date());
	const [endTime, setEndTime] = React.useState(new Date());
	const [option, setOption] = React.useState<string | null>(null);

	let date1: Date | Date[] | undefined = new Date();
	let date2: Date | Date[] | undefined = new Date();


	const [cookies] = useCookies(['token']);

	const classes = useStyles();

	const token = cookies.token;
	let errorMessage: string | null = null;

	const handleChange = (event: any, newValue: string | null) => {
		setOption(newValue);
	};

	const checkValidRequest = (startTime: any, endTime: any) => {
		let totalMinutes: number = 0;
		props.downTimeRequests.forEach((request: any) => {
			totalMinutes += request.minutes;
		});
		let inValid = false;
		if (
			endTime === null ||
			startTime === null ||
			option === '' ||
			option === null
		) {
			errorMessage = 'Please fill out all fields';
			inValid = true;
		}
		if (endTime && startTime) {
			if (moment(endTime).isBefore(moment(startTime))) {
				errorMessage = 'End time must be after start time';
				inValid = true;
			}
			let duration = moment.duration(
				moment(endTime).diff(moment(startTime))
			);
			const max = 480;
			if (totalMinutes + duration.asMinutes() > max) {
				errorMessage =
					'Request would exceed the maximum amount of time you can request';
				inValid = true;
			}
		}
		props.downTimeRequests.forEach((request: any) => {
			if (
				request.start_time === Math.trunc(startTime / 1000) ||
				request.end_time === Math.trunc(endTime / 1000)
			) {
				errorMessage = 'You already have a request for that time';
				inValid = true;
			}
			if (
				(Math.trunc(startTime / 1000) > request.start_time &&
					Math.trunc(startTime / 1000) < request.end_time) ||
				(Math.trunc(endTime / 1000) > request.start_time &&
					Math.trunc(endTime / 1000) < request.end_time)
			) {
				errorMessage = 'This request overlaps with another request';
				inValid = true;
			}
		});
		return inValid;
	};

	const handleDownTimeRequest = async () => {
		try {

			console.log(startTime);
			console.log(endTime);

			await trackPromise(

				trainerReportsAPI.requestDownTime(
					props.user.id,
					startTime.getTime(),
					endTime.getTime(),
					option,
					token
				)
			);
		} catch (error) {
			console.error(error);
		}
		props.handleUpdateDowntime();
		setStartTime(new Date());
		setEndTime(new Date());
		setOption(null);
		handleChange(null, null);
	};

	function setDate1(date: Date | Date[] | undefined) {
		console.log(date);
		date1 = date;
		console.log(date1);
	}

	function setDate2(date: Date | Date[] | undefined) {
		console.log(date);
		date2 = date;
		console.log(date2);
	}

	return (
		<div>
			<div className={classes.cardLabelContainer}>
				<span className={classes.cardLabel}>Downtime Requests:</span>
			</div>
			<Card className={classes.card}>
				<div className={classes.flexEvenly}>
					<div className={classes.flexCenter}>
						<div className={classes.marginRight}>Start:</div>

						<Calendar style={{width: '100px'}}  timeOnly showTime hourFormat="12" value={startTime} onChange={(e: any) => setStartTime(e.value)}></Calendar>

						{/* <LocalizationProvider dateAdapter={AdapterMoment}>
							<TimePicker
								label='Start Time'
								value={startTime}
								onChange={(newValue: any) => {
									setStartTime(newValue.valueOf());
								}}
								renderInput={(params) => (
									<TextField {...params} />
								)}
							/>
						</LocalizationProvider> */}
					</div>
					<div className={classes.flexCenter}>
						<div className={classes.marginRight}>End:</div>

						<Calendar style={{width: '100px'}} timeOnly showTime hourFormat="12" value={endTime} onChange={(e: any) => setEndTime(e.value)}></Calendar>

						{/* <LocalizationProvider dateAdapter={AdapterMoment}>
							<TimePicker
								label='End Time'
								value={endTime}
								onChange={(newValue: any) => {
									setEndTime(newValue.valueOf());
								}}
								renderInput={(params) => (
									<TextField {...params} />
								)}
							/>
						</LocalizationProvider> */}
					</div>
					<div className={classes.flexCenter}>

					<div className={classes.marginRight}>Options:</div>

						<Dropdown value={option} options={options} onChange={(e: any) => setOption(e.value)} placeholder="Select one ..."/>

					</div>

					<div className={classes.flexCenter}>
						<Button
							variant='contained'
							onClick={() => handleDownTimeRequest()}
							disabled={checkValidRequest(startTime, endTime)}>
							Submit
						</Button>
						
					</div>


				</div>
				<div className={classes.optionContainer}>
					{/* <div className={classes.autocomplete}>
						<div className={classes.marginRight}>Options:</div>

						<Dropdown value={option} options={options} onChange={(e: any) => setOption(e.value)} placeholder="Select one ..."/>
					
						<Autocomplete
							disablePortal
							clearOnBlur
							options={options}
							sx={{ width: 259}}
							renderInput={(params) => (
								<TextField {...params} label='Option' />
							)}
							onChange={(event: any, newValue: string | null) => {
								handleChange(event, newValue);
							}}
							value={option}
						/>
					</div> */}
					
					{errorMessage && (
							<span className={classes.red}>{errorMessage}</span>
						)}




				</div>
			</Card>
		</div>
	);
}

export default DownTimeRequests;
