/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import { makeStyles, Theme } from '@material-ui/core/styles';

import {
	Drawer,
	AppBar,
	CssBaseline,
	Toolbar,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
} from '@material-ui/core';

import { trackPromise } from 'react-promise-tracker';

import loginAPI from '../API/loginAPI';

import { ExitToApp } from '@mui/icons-material';

import { useCookies } from 'react-cookie';

import Logo from '../Assets/Dark Blue Logo.png';

import moment from 'moment';

import '../App.css';
import ApplicationPanel from '../Pages/ApplicationPanel';
import TrainerReportsPanel from '../Pages/TrainerReportsPanel';
import trainerReportsAPI from '../API/trainerReportsAPI';
import adminReportsAPI from '../API/adminReportsAPI';
import AdminSlipPanel from '../Pages/AdminSlipPanel';
import AdminSessionPanel from '../Pages/AdminSessionPanel';
import AdminDispositionPanel from '../Pages/AdminDispositionPanel';
import AdminNavList from './AdminNavList';
import NavList from './NavList';

import {
	SlipTotals,
	SlipDispositions,
	Dispositions,
	Sessions,
	SessionTrainer,
	Slips,
	CompletedSlips,
	DownTimes,
} from '../@types/InterfaceDefinitions';
import SLXDispositionPanel from '../Pages/SLXDispositionPanel';
import TopsNavList from './TopsNavList';

const drawerWidth = 249;

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		display: 'flex',
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		backgroundColor: '#ffffff',
		boxShadow:
			'0px 0px 4px -1px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 1px 7px 0px rgb(0 0 0 / 12%)',
		'& div': {
			minHeight: '100px',
		},
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		[theme.breakpoints.down('lg')]: {
			width: '200px',
		},
	},
	drawerPaper: {
		width: drawerWidth,
		backgroundColor: '#000352',
		color: '#ffffff',
		[theme.breakpoints.down('lg')]: {
			width: '200px',
		},
	},
	drawerContainer: {
		overflow: 'auto',
		paddingTop: '70px',
	},
	list: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		marginTop: '20px',
	},
	content: {
		flexGrow: 1,
		backgroundColor: '#efefef',
		marginTop: '100px',
		height: 'calc(100vh - 100px)',
	},
	headerLogo: {
		height: '75px',
		width: '250px',
		marginLeft: '7px',
	},
	headerLogoutButton: {
		color: '#ffffff',
		fontSize: '20px',
		fontWeight: 'bold',
		position: 'relative',
		top: '325px',
		fontFamily: `'League Spartan', sans-serif`,
		letterSpacing: 'normal',
		[theme.breakpoints.down('lg')]: {
			top: '280px',
			fontSize: '16px',
		},
	},
	headerLogoutIcon: {
		marginTop: '10px',
		marginRight: '10px',
	},
	icon: {
		fontSize: '2rem',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1.5rem',
		},
	},
	listItem: {
		'& span': {
			letterSpacing: 'normal',
			fontSize: '14px',
			textTransform: 'uppercase',
		},
		[theme.breakpoints.down('lg')]: {
			'& span': {
				fontSize: '12px',
			},
		},
	},
	drawerAvatar: {
		width: '105px',
		height: '105px',
		borderRadius: '50%',
		margin: '0 auto',
		display: 'flex',
		justifyContent: 'center',
		[theme.breakpoints.down('lg')]: {
			width: '75px',
			height: '75px',
			fontSize: '24px',
		},
	},
	flexCenter: {
		display: 'flex',
		justifyContent: 'center',
		padding: theme.spacing(3),
	},
	flexStart: {
		display: 'flex',
		justifyContent: 'flex-start',
	},
	avatarImg: {
		width: '105px',
		height: '105px',
		border: '2px solid lightgrey',
		borderRadius: '50%',
		[theme.breakpoints.down('lg')]: {
			width: '75px',
			height: '75px',
		},
	},
	listItemIcon: {
		color: '#ffffff',
		marginLeft: '26px',
		[theme.breakpoints.down('lg')]: {
			marginLeft: '10px',
		},
	},
	navListItem: {
		paddingBottom: '5px',
		marginBottom: '10px',
		paddingTop: '3px',
	},
	userData: {
		fontWeight: 'bold',
		fontSize: 22,
		marginBottom: 10,
		textTransform: 'uppercase',
		textDecoration: 'underline',
	},
	trainerName: {
		fontSize: 18,
	},
	flexColumn: {
		display: 'flex',
		flexDirection: 'column',
	},
}));

function NavigationDrawer(props: any): JSX.Element {
	const [adminNavOpen, setAdminNavOpen] = React.useState(false);
	const [topsNavOpen, setTopsNavOpen] = React.useState(false);
	const [selectedDrawerButton, setSelectedDrawerButton] = React.useState(0);
	const [slipTotals, setSlipTotals] = React.useState({} as SlipTotals);
	const [selectedSlip, setSelectedSlip] = React.useState(null);
	const [slipDispositions, setSlipDispositions] = React.useState(
		[] as SlipDispositions
	);
	const [completedSlips, setCompletedSlips] = React.useState(
		[] as CompletedSlips
	);
	const [downTimeRequests, setDownTimeRequests] = React.useState(
		[] as DownTimes
	);
	const [allSlips, setAllSlips] = React.useState([] as Slips);
	const [allCompletedSlips, setAllCompletedSlips] = React.useState(
		[] as Sessions
	);
	const [allDispositions, setAllDispositions] = React.useState(
		[] as Dispositions
	);

	const [slxDispositions, setSlxDispositions] = React.useState([]) as any;
	const classes = useStyles();

	const [cookies, setCookie, removeCookie] = useCookies(['token']);

	const history = useHistory();

	const handleLogout = () => {
		removeCookie('token', { path: '/' });
		history.push('/login');
	};

	const getTrainerReportInfo = async () => {
		console.log('getting trainer report info');
		try {
			const slipTotalsResponse = await trackPromise(
				trainerReportsAPI.getSlipTotals(
					props.user.id,
					moment(Date.now()).startOf('day').valueOf(),
					moment(Date.now()).endOf('day').valueOf(),
					token
				)
			);
			setSlipTotals(slipTotalsResponse.data);
			const slipDispostionsResponse =
				await trainerReportsAPI.getSlipDispositions(
					props.user.id,
					moment(Date.now()).startOf('day').valueOf(),
					moment(Date.now()).endOf('day').valueOf(),
					token
				);
			setSlipDispositions(slipDispostionsResponse.data);
			const completedSlipsResponse =
				await trainerReportsAPI.getCompletedSlips(
					props.user.id,
					moment(Date.now()).startOf('day').valueOf(),
					moment(Date.now()).endOf('day').valueOf(),
					token
				);
			setCompletedSlips(completedSlipsResponse.data);
			const downTimeResponse = await trainerReportsAPI.getDownTime(
				props.user.id,
				token
			);
			setDownTimeRequests(downTimeResponse.data);
		} catch (error) {
			console.error(error);
		}
	};

	const token: string = cookies.token;

	const handleDrawerNavClick = async (value: number) => {
		setSelectedDrawerButton(value);
		if (value === 1) {
			getTrainerReportInfo();
		} else if (value === 2) {
			try {
				const slxResponse = await trainerReportsAPI.getSLXSDispositions(
					moment(Date.now()).startOf('day').valueOf(),
					moment(Date.now()).endOf('day').valueOf(),
					token
				);
				setSlxDispositions(slxResponse.data);
			} catch (error) {
				console.error(error);
			}
		} else if (value === 3) {
			try {
				const allSlipsResponse = await trackPromise(
					adminReportsAPI.getAllSlips(token)
				);
				setAllSlips(allSlipsResponse.data.slips);
				if (allSlipsResponse.data.ticketOwned) {
					setSelectedSlip(allSlipsResponse.data.ticketOwned);
				}
			} catch (error) {
				console.error(error);
			}
		} else if (value === 4) {
			try {
				const allCompletedSlipsResponse = await trackPromise(
					adminReportsAPI.getCompletedSlips(
						moment(Date.now()).startOf('day').valueOf(),
						moment(Date.now()).endOf('day').valueOf(),
						token
					)
				);
				allCompletedSlipsResponse.data.forEach(
					(session: SessionTrainer) => {
						session.average = parseFloat(
							session.average.toFixed(2)
						);
					}
				);
				setAllCompletedSlips(allCompletedSlipsResponse.data);
			} catch (error) {
				console.error(error);
			}
		} else {
			try {
				const allDispositionsResponse = await trackPromise(
					adminReportsAPI.getSlipDispositions(
						moment(Date.now()).startOf('day').valueOf(),
						moment(Date.now()).endOf('day').valueOf(),
						token
					)
				);
				const usersObj: any = {};
				const updatedUsersArr: any[] = [];
				for (const u in allDispositionsResponse.data) {
					const { name, final_status, total } =
						allDispositionsResponse.data[u];
					if (name in usersObj) {
						usersObj[name][final_status] = total;
					} else {
						usersObj[name] = {
							name,
							[final_status]: total,
						};
					}
				}

				for (const key in usersObj) {
					updatedUsersArr.push(usersObj[key]);
				}

				setAllDispositions(updatedUsersArr);
			} catch (error) {
				console.error(error);
			}
		}
	};

	const handleUpdateDowntime = async () => {
		try {
			const downTimeResponse = await trainerReportsAPI.getDownTime(
				props.user.id,
				token
			);
			setDownTimeRequests(downTimeResponse.data);
		} catch (error) {
			console.error(error);
		}
	};

	const handleUpdateAdminSlips = async () => {
		try {
			const allSlipsResponse = await trackPromise(
				adminReportsAPI.getAllSlips(token)
			);
			if (allSlipsResponse.data.ticketOwned) {
				setSelectedSlip(allSlipsResponse.data.ticketOwned);
			} else {
				setSelectedSlip(null);
			}
			setAllSlips(allSlipsResponse.data.slips);
		} catch (error) {
			console.error(error);
		}
	};

	const handleAdminNavClick = () => {
		setAdminNavOpen(!adminNavOpen);
	};

	const handleTopsNavClick = () => {
		setTopsNavOpen(!topsNavOpen);
	};

	useEffect(() => {
		async function login() {
			try {
				const response = await trackPromise(
					loginAPI.login(cookies.token)
				);
				props.getUser(response.data);
				props.handleUserLoggedIn(true);
			} catch (error) {
				alert('Invalid username or password');
				removeCookie('token', { path: '/' });
				console.error(error);
			}
		}
		if (!cookies.token) {
			handleLogout();
		} else if (!props.user && cookies.token) {
			login();
		} else {
			return;
		}
	});

	return (
		<div className={classes.root}>
			<CssBaseline />
			<AppBar position='fixed' className={classes.appBar}>
				<Toolbar>
					<img
						className={classes.headerLogo}
						alt='Applied Concepts Logo'
						src={Logo}
					/>
				</Toolbar>
			</AppBar>
			<Drawer
				className={classes.drawer}
				variant='permanent'
				classes={{
					paper: classes.drawerPaper,
				}}>
				<Toolbar />
				<div className={classes.drawerContainer}>
					<div className={classes.flexColumn}>
						<span className={classes.userData}>
							{props.user ? props.user.slxUserId : ''}
						</span>
						<span className={classes.trainerName}>
							{props.user ? props.user.name : ''}
						</span>
					</div>
					<List>
						<NavList
							handleDrawerNavClick={handleDrawerNavClick}
							selectedDrawerButton={selectedDrawerButton}
						/>
						<TopsNavList
							topsNavOpen={topsNavOpen}
							handleTopsNavClick={handleTopsNavClick}
							selectedDrawerButton={selectedDrawerButton}
						/>
						{props.user && props.user.teamLeader && (
							<AdminNavList
								adminNavOpen={adminNavOpen}
								handleAdminNavClick={handleAdminNavClick}
								handleDrawerNavClick={handleDrawerNavClick}
								selectedDrawerButton={selectedDrawerButton}
							/>
						)}
					</List>
				</div>
				<div>
					<List className={classes.list}>
						<ListItem
							className={classes.navListItem}
							onClick={() => handleLogout()}
							button>
							<ListItemIcon className={classes.listItemIcon}>
								<ExitToApp />
							</ListItemIcon>
							<ListItemText
								className={classes.listItem}
								primary='Logout'
							/>
						</ListItem>
					</List>
				</div>
			</Drawer>
			<main className={classes.content}>
				{selectedDrawerButton === 0 && (
					<div className={classes.flexCenter}>
						<ApplicationPanel {...props} />
					</div>
				)}
				{selectedDrawerButton === 1 && (
					<TrainerReportsPanel
						slipTotals={slipTotals}
						slipDispositions={slipDispositions}
						completedSlips={completedSlips}
						downTimeRequests={downTimeRequests}
						setSlipTotals={setSlipTotals}
						setSlipDispositions={setSlipDispositions}
						setCompletedSlips={setCompletedSlips}
						handleUpdateDowntime={handleUpdateDowntime}
						getTrainerReportInfo={getTrainerReportInfo}
						user={props.user}
						{...props}
					/>
				)}
				{selectedDrawerButton === 2 && (
					<SLXDispositionPanel
						allDispositions={slxDispositions}
						setAllDispositions={setSlxDispositions}
						trainer={props.user}
						{...props}
					/>
				)}
				{selectedDrawerButton === 3 && (
					<AdminSlipPanel
						user={props.user}
						allSlips={allSlips}
						selectedSlip={selectedSlip}
						setSelectedSlip={setSelectedSlip}
						handleUpdateAdminSlips={handleUpdateAdminSlips}
						{...props}
					/>
				)}
				{selectedDrawerButton === 4 && (
					<AdminSessionPanel
						allCompletedSlips={allCompletedSlips}
						setAllCompletedSlips={setAllCompletedSlips}
						{...props}
					/>
				)}
				{selectedDrawerButton === 5 && (
					<AdminDispositionPanel
						allDispositions={allDispositions}
						setAllDispositions={setAllDispositions}
						{...props}
					/>
				)}
			</main>
		</div>
	);
}

export default NavigationDrawer;
