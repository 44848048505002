import React from 'react';
import { Button, TextField, Dialog, DialogContent } from '@mui/material';

import { DateTimePicker, LocalizationProvider } from '@mui/lab';

import AdapterMoment from '@mui/lab/AdapterMoment';

import { makeStyles, Theme } from '@material-ui/core/styles';

import { trackPromise } from 'react-promise-tracker';
import adminReportsAPI from '../API/adminReportsAPI';

import { useCookies } from 'react-cookie';

import { Slip, User } from '../@types/InterfaceDefinitions';

const useStyles = makeStyles((theme: Theme) => ({
	buttonContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		marginBottom: '10px',
	},
	submitButton: {
		width: 200,
		color: '#ffffff',
		backgroundColor: '#000352 !important',
	},
	button: {
		width: 100,
	},
	returnContentContainer: {
		width: '500px',
		height: '300px',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
	},
	rescheduleContentContainer: {
		width: '400px',
		height: '200px',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
	},
	contentLabel: {
		textDecoration: 'underline',
		textTransform: 'uppercase',
		fontFamily: `'League Spartan', sans-serif`,
		color: '#707070',
		marginBottom: '10px',
	},
	flexEnd: {
		display: 'flex',
		justifyContent: 'flex-end',
	},
	column: {
		display: 'flex',
		flexDirection: 'column',
	},
}));
interface ISlipModalButtonsProps {
	selectedSlip: Slip | null;
	user: User;
	handleSlipModalToggle: (selectedSlip: Slip | null) => void;
	dispositionOption: string;
	setDispositionOption: (dispositionOption: string) => void;
	setSlipModalOpen: (slipModalOpen: boolean) => void;
	handleUpdateAdminSlips: any;
}

function SlipModalButtons(props: ISlipModalButtonsProps) {
	const [rescheduleAnchorEl, setRescheduleAnchorEl] =
		React.useState<HTMLButtonElement | null>(null);
	const [returnAnchorEl, setReturnAnchorEl] =
		React.useState<HTMLButtonElement | null>(null);
	const [password, setPassword] = React.useState<string>('');
	const [comments, setComments] = React.useState<string>('');
	const [rescheduleTime, setRescheduleTime] = React.useState<Date | null>(
		new Date()
	);

	const classes = useStyles();

	const [cookies] = useCookies(['token']);
	const token = cookies.token;

	const reschedulePopperOpen = Boolean(rescheduleAnchorEl);
	const returnPopperOpen = Boolean(returnAnchorEl);

	const handleRescheduleClick = (
		event: React.MouseEvent<HTMLButtonElement>
	) => {
		setRescheduleAnchorEl(event.currentTarget);
	};

	const handleReturnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setReturnAnchorEl(event.currentTarget);
	};

	const handleRescheduleClose = () => {
		setRescheduleAnchorEl(null);
	};

	const handleReturnClose = () => {
		setReturnAnchorEl(null);
	};

	const handlePasswordChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setPassword(event.target.value);
	};

	const handleCommentsChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setComments(event.target.value);
	};

	const handleReturnSlip = async () => {
		const action = 'return';
		const options = {
			password: password,
			comments: comments,
		};
		try {
			await trackPromise(
				adminReportsAPI.dispositionSlip(
					props.selectedSlip,
					props.user.id,
					action,
					options,
					token
				)
			);
		} catch (error) {
			console.error(error);
		}
		setReturnAnchorEl(null);
		props.setSlipModalOpen(false);
		props.handleUpdateAdminSlips();
	};

	const handleCompleteSlip = async () => {
		const action = 'complete';
		const options = {
			disposition: props.dispositionOption,
		};
		try {
			await trackPromise(
				adminReportsAPI.dispositionSlip(
					props.selectedSlip,
					props.user.id,
					action,
					options,
					token
				)
			);
		} catch (error) {
			console.error(error);
		}
		props.setDispositionOption('');
		props.setSlipModalOpen(false);
		props.handleUpdateAdminSlips();
	};

	const handleRescheduleSlip = async () => {
		const action = 'reschedule';
		const options = {
			rescheduleTime: rescheduleTime?.valueOf(),
		};
		try {
			await trackPromise(
				adminReportsAPI.dispositionSlip(
					props.selectedSlip,
					props.user.id,
					action,
					options,
					token
				)
			);
		} catch (error) {
			console.error(error);
		}
		setRescheduleAnchorEl(null);
		props.setSlipModalOpen(false);
		props.handleUpdateAdminSlips();
	};

	return (
		<div className={classes.buttonContainer}>
			<Button
				className={classes.button}
				variant='text'
				onClick={handleReturnClick}>
				Return
			</Button>
			<Dialog
				disableEnforceFocus
				open={returnPopperOpen}
				maxWidth='md'
				keepMounted>
				<DialogContent>
					<div className={classes.returnContentContainer}>
						<span className={classes.contentLabel}>
							Return Slip to Queue
						</span>
						<TextField
							type='password'
							placeholder='Password'
							value={password}
							onChange={handlePasswordChange}></TextField>
						<TextField
							placeholder='Comments...'
							value={comments}
							onChange={handleCommentsChange}
							multiline
							minRows={3}></TextField>
						<div className={classes.flexEnd}>
							<Button
								disabled={
									password !== 'molly' || comments === ''
								}
								className={classes.button}
								onClick={handleReturnSlip}>
								Confirm
							</Button>
							<Button
								className={classes.button}
								onClick={handleReturnClose}>
								Cancel
							</Button>
						</div>
					</div>
				</DialogContent>
			</Dialog>
			<Button
				className={classes.submitButton}
				onClick={handleCompleteSlip}
				variant='contained'
				disableElevation>
				Complete Slip
			</Button>
			<Button
				className={classes.button}
				variant='text'
				onClick={handleRescheduleClick}>
				Reschedule
			</Button>
			<Dialog
				disableEnforceFocus
				open={reschedulePopperOpen}
				maxWidth='md'
				keepMounted>
				<DialogContent>
					<div className={classes.rescheduleContentContainer}>
						<div className={classes.column}>
							<span className={classes.contentLabel}>
								Reschedule Call
							</span>
							<LocalizationProvider dateAdapter={AdapterMoment}>
								<DateTimePicker
									renderInput={(props) => (
										<TextField {...props} />
									)}
									value={rescheduleTime}
									onChange={(newValue) =>
										setRescheduleTime(newValue)
									}
								/>
							</LocalizationProvider>
						</div>
						<div className={classes.flexEnd}>
							<Button onClick={handleRescheduleSlip}>
								Confirm
							</Button>
							<Button onClick={handleRescheduleClose}>
								Cancel
							</Button>
						</div>
					</div>
				</DialogContent>
			</Dialog>
		</div>
	);
}

export default SlipModalButtons;
