/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';
import { DateRange } from '@mui/lab';

import { useCookies } from 'react-cookie';
import { trackPromise } from 'react-promise-tracker';

import { makeStyles, Theme } from '@material-ui/core/styles';

import AdminSessionTable from '../Components/AdminSessionTable';
import DatePicker from '../Components/DatePicker';
import adminReportsAPI from '../API/adminReportsAPI';

import moment from 'moment';

import { Sessions, SessionTotalsList } from '../@types/InterfaceDefinitions';

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		padding: '10px 0px',
		width: '100%',
	},
}));
interface IAdminSessionProps {
	allCompletedSlips: Sessions;
	setAllCompletedSlips: (sessions: Sessions) => void;
}

function AdminSessionPanel(props: IAdminSessionProps) {
	const [tableData, setTableData] = React.useState(props.allCompletedSlips);
	const [startDate, setStartDate] = React.useState(
		moment(Date.now()).format('MM/DD/YY')
	);
	const [endDate, setEndDate] = React.useState(
		moment(Date.now()).format('MM/DD/YY')
	);
	const [datePickerOpen, setDatePickerOpen] = React.useState(false);
	const classes = useStyles();

	const [cookies] = useCookies(['token']);
	const token = cookies.token;

	let totalMinutes: number = 0;
	let totalTickets: number = 0;
	let totalAverage: number = 0;

	tableData.forEach((trainer: any) => {
		totalMinutes += trainer.minutes;
		totalTickets += trainer.total;
		totalAverage += trainer.average;
	});

	totalAverage = parseFloat(
		(totalAverage / props.allCompletedSlips.length).toFixed(2)
	);

	const totalData: SessionTotalsList = [
		{
			name: 'Total',
			total: totalTickets,
			minutes: totalMinutes,
			average: totalAverage,
		},
	];

	const handleDatePickerToggle = () => {
		setDatePickerOpen(!datePickerOpen);
		setStartDate(moment(Date.now()).format('MM/DD/YY'));
		setEndDate(moment(Date.now()).format('MM/DD/YY'));
	};

	const handleDateChange = async (start: number, end: number) => {
		setStartDate(moment(start).format('MM/DD/YY'));
		setEndDate(moment(end).format('MM/DD/YY'));
		try {
			const response = await trackPromise(
				adminReportsAPI.getCompletedSlips(start, end, token)
			);
			setTableData(response.data);
			props.setAllCompletedSlips(response.data);
		} catch (error) {
			console.error(error);
		}
		setDatePickerOpen(false);
	};

	useEffect(() => {
		if (tableData.length === 0) {
			setTableData(props.allCompletedSlips);
		}
	}, [props.allCompletedSlips, tableData.length]);

	return (
		<div className={classes.root}>
			<DatePicker
				datePickerOpen={datePickerOpen}
				handleDatePickerToggle={handleDatePickerToggle}
				handleDateChange={handleDateChange}
				startDate={startDate}
				endDate={endDate}
				setEndDate={setEndDate}
				setStartDate={setStartDate}
			/>
			<AdminSessionTable
				ticketData={props.allCompletedSlips}
				totalsData={totalData}
				handleDatePickerToggle={handleDatePickerToggle}
				startDate={startDate}
				endDate={endDate}
			/>
		</div>
	);
}

export default AdminSessionPanel;
