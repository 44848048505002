/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { useCookies } from 'react-cookie';

import SLXDispositionTable from '../Components/SLXDispositionTable';
import DatePicker from '../Components/DatePicker';

import moment from 'moment';

import { makeStyles, Theme } from '@material-ui/core/styles';

import { SLXDispositions, SLXTrainer } from '../@types/InterfaceDefinitions';
import trainerReportsAPI from '../API/trainerReportsAPI';

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		padding: '18px 0px 10px 0px',
		width: '100%',
	},
}));

interface IAdminDispositionPanelProps {
	trainer: SLXTrainer;
	allDispositions: SLXDispositions;
	setAllDispositions: (dispositions: SLXDispositions) => void;
}

function SLXDispositionPanel(props: IAdminDispositionPanelProps) {
	const [tableData, setTableData] = React.useState(props.allDispositions);

	const [startDate, setStartDate] = React.useState(
		moment(Date.now()).format('MM/DD/YY')
	);
	const [endDate, setEndDate] = React.useState(
		moment(Date.now()).format('MM/DD/YY')
	);
	const [datePickerOpen, setDatePickerOpen] = React.useState(false);

	const classes = useStyles();

	const [cookies] = useCookies(['token']);

	const token = cookies.token;

	let totalSessions = 0;
	let totalCLM = 0;
	let totalCC = 0;
	let totalINCPS = 0;
	let totalRESCH = 0;
	let totalAPPT = 0;

	tableData.forEach((trainer: SLXTrainer) => {
		totalSessions += trainer.complete ? trainer.complete : 0;
		totalCLM += trainer.CLM ? trainer.CLM : 0;
		totalCC += trainer.CC ? trainer.CC : 0;
		totalINCPS += trainer.incps ? trainer.incps : 0;
		totalRESCH += trainer.resch ? trainer.resch : 0;
		totalAPPT += trainer.appt ? trainer.appt : 0;
	});

	const totalData = [
		{
			trainer: 'Totals:',
			sessions: totalSessions,
			clm: totalCLM,
			cc: totalCC,
			incps: totalINCPS,
			resch: totalRESCH,
			appt: totalAPPT,
		},
	];

	const handleDatePickerToggle = () => {
		setDatePickerOpen(!datePickerOpen);
		setStartDate(moment(Date.now()).format('MM/DD/YY'));
		setEndDate(moment(Date.now()).format('MM/DD/YY'));
	};

	const handleDateChange = async (start: number, end: number) => {
		setStartDate(moment(startDate).format('MM/DD/YY'));
		setEndDate(moment(endDate).format('MM/DD/YY'));
		try {
			const response = await trackPromise(
				trainerReportsAPI.getSLXSDispositions(start, end, token)
			);
			props.setAllDispositions(response.data);
		} catch (error) {
			console.error(error);
		}
		setDatePickerOpen(false);
	};

	useEffect(() => {
		if (tableData !== props.allDispositions) {
			setTableData(props.allDispositions);
		}
	}, [tableData, props.allDispositions]);

	return (
		<div className={classes.root}>
			<DatePicker
				datePickerOpen={datePickerOpen}
				handleDatePickerToggle={handleDatePickerToggle}
				handleDateChange={handleDateChange}
				startDate={startDate}
				endDate={endDate}
				setStartDate={setStartDate}
				setEndDate={setEndDate}
			/>
			<SLXDispositionTable
				tableData={tableData}
				totalsData={totalData}
				startDate={startDate}
				endDate={endDate}
				handleDatePickerToggle={handleDatePickerToggle}
				trainer={props.trainer}
			/>
		</div>
	);
}

export default SLXDispositionPanel;
