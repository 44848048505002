import React from 'react';
import {
	FormControlLabel,
	FormControl,
	Radio,
	RadioGroup,
} from '@mui/material';

import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
	radioContainer: {
		border: '1px solid #707070',
		padding: '10px 0px',
		margin: '20px 0px',
	},
	radioGroup1: {
		display: 'flex',
		padding: '0px 30px',
		justifyContent: 'space-between',
	},
	radioGroup2: {
		display: 'flex',
		padding: '0px 30px',
	},
	fullWidth: {
		width: '100% !important',
	},
	ogcg: {
		marginLeft: '106px !important',
	},
	limit1: {
		marginLeft: '69px !important',
	},
	limit2: {
		marginLeft: '60px !important',
	},
}));

interface ISlipModalRadiosProps {
	dispositionOption: string;
	handleDispositionChange: (
		event: React.ChangeEvent<HTMLInputElement>
	) => void;
}

function SlipModalRadios(props: ISlipModalRadiosProps) {
	const classes = useStyles();
	return (
		<div className={classes.radioContainer}>
			<FormControl component='fieldset' className={classes.fullWidth}>
				<RadioGroup
					className={classes.radioGroup1}
					value={props.dispositionOption}
					onChange={props.handleDispositionChange}
					row
					aria-label='gender'
					name='row-radio-buttons-group'>
					<FormControlLabel
						value='sessionDone'
						control={<Radio />}
						label='Session Done'
					/>
					<FormControlLabel
						value='clm'
						control={<Radio />}
						label='C/LM'
					/>
					<FormControlLabel
						value='cc'
						control={<Radio />}
						label='CC'
					/>
					<FormControlLabel
						value='incps'
						control={<Radio />}
						label='INCPS'
					/>
				</RadioGroup>
				<RadioGroup
					className={classes.radioGroup2}
					value={props.dispositionOption}
					onChange={props.handleDispositionChange}
					row
					aria-label='gender'
					name='row-radio-buttons-group'>
					<FormControlLabel
						value='noAns'
						control={<Radio />}
						label='No Answer'
					/>
					<FormControlLabel
						value='ogcg'
						control={<Radio />}
						label='OG/CG'
						className={classes.ogcg}
					/>
					<FormControlLabel
						value='limit1'
						control={<Radio />}
						label='Limit 1'
						className={classes.limit1}
					/>
					<FormControlLabel
						value='limit2'
						control={<Radio />}
						label='Limit 2'
						className={classes.limit2}
					/>
				</RadioGroup>
			</FormControl>
		</div>
	);
}

export default SlipModalRadios;
