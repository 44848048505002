import React from 'react';
import { AutoSizer, Column, Table, Size } from 'react-virtualized';

import { Icon, IconButton, Tooltip } from '@mui/material';

import { makeStyles, Theme } from '@material-ui/core/styles';

import {
	PhoneIphone,
	Phone,
	Check,
	ClosedCaptionOff,
	MoreHoriz,
	Remove,
	Receipt,
	NightsStay,
	Schedule,
	Today,
} from '@mui/icons-material';

import moment from 'moment';

import 'react-virtualized/styles.css';

const useStyles = makeStyles((theme: Theme) => ({
	yellow: {
		color: '#c5c530',
	},
	orange: {
		color: '#d65200',
	},
	green: {
		color: 'green',
	},
	blue: {
		color: '#000352 !important',
	},
	flex: {
		flex: '1 1 auto',
	},
}));

function AdminSlipsTable(props: any) {
	const classes = useStyles();

	const slipData =
		props.filteredSlips.length > 0
			? props.filteredSlips.sort((a: any, b: any) =>
					b.callDate.localeCompare(a.callDate)
			  )
			: props.slips.sort((a: any, b: any) =>
					b.callDate.localeCompare(a.callDate)
			  );

	const typeRenderer = (rowData: any) => {
		if (rowData.mobile) {
			return (
				<React.Fragment>
					<Tooltip title='Mobile'>
						<Icon>
							<PhoneIphone />
						</Icon>
					</Tooltip>
					{rowData.overnight && (
						<Tooltip title='Overnight'>
							<Icon className={classes.yellow}>
								<NightsStay />
							</Icon>
						</Tooltip>
					)}
					{rowData.rescheduled && (
						<Tooltip title='Rescheduled'>
							<Icon>
								<Schedule />
							</Icon>
						</Tooltip>
					)}
					{rowData.appointment && (
						<Tooltip title='Appointment'>
							<Icon>
								<Today />
							</Icon>
						</Tooltip>
					)}
					{rowData.ccStatus && (
						<Tooltip title='Courtesy Call'>
							<Icon>
								<ClosedCaptionOff />
							</Icon>
						</Tooltip>
					)}
				</React.Fragment>
			);
		} else {
			return (
				<React.Fragment>
					<Tooltip title='Phone'>
						<Icon className={classes.orange}>
							<Phone />
						</Icon>
					</Tooltip>
					{rowData.overnight && (
						<Tooltip title='Overnight'>
							<Icon className={classes.yellow}>
								<NightsStay />
							</Icon>
						</Tooltip>
					)}
					{rowData.rescheduled && (
						<Tooltip title='Rescheduled'>
							<Icon>
								<Schedule />
							</Icon>
						</Tooltip>
					)}
					{rowData.appointment && (
						<Tooltip title='Appointment'>
							<Icon>
								<Today />
							</Icon>
						</Tooltip>
					)}
					{rowData.ccStatus && (
						<Tooltip title='Courtesy Call'>
							<Icon>
								<ClosedCaptionOff />
							</Icon>
						</Tooltip>
					)}
				</React.Fragment>
			);
		}
	};

	return (
		<div className={classes.flex}>
			<AutoSizer>
				{({ height, width }: Size) => (
					<Table
						rowCount={slipData.length}
						rowGetter={({ index }) => slipData[index]}
						height={height}
						width={width}
						rowHeight={50}
						headerHeight={50}
						headerStyle={{
							backgroundColor: '#000352',
							color: '#ffffff',
							borderRight: '2px solid #dadad9',
							textAlign: 'center',
							fontSize: '14px',
							fontWeight: '500',
							height: '48px',
							margin: '0px',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
						rowStyle={({ index }) => {
							if (index % 2 && index !== -1) {
								return {
									backgroundColor: '#dadad9',
									alignItems: 'center',
								};
							} else {
								return { alignItems: 'center' };
							}
						}}
						style={{
							fontSize: '14px',
							fontFamily:
								'roboto, "Open Sans", Helvetica, sans-serif',
							paddingBottom: '20px',
						}}>
						<Column
							label='Full Name'
							dataKey='fullName'
							width={200}
							flexGrow={1}
							cellDataGetter={({ rowData }) =>
								rowData.firstName + ' ' + rowData.lastName
							}
						/>
						<Column
							label='Dealership'
							dataKey='dealership'
							width={200}
							flexGrow={1}
						/>
						<Column
							label='Call Date'
							dataKey='callDate'
							width={200}
							flexGrow={1}
							cellDataGetter={({ rowData }) =>
								moment(rowData.callDate).format(
									'MM/DD/YYYY hh:mm A'
								)
							}
						/>
						<Column
							label='Call Back #'
							dataKey='callBackNumber'
							width={125}
							flexGrow={1}
							cellDataGetter={({ rowData }) => {
								const cleaned = (
									'' + rowData.callBackNumber
								).replace(/\D/g, '');
								const match = cleaned.match(
									/^(\d{3})(\d{3})(\d{4})$/
								);
								if (match) {
									return (
										'(' +
										match[1] +
										') ' +
										match[2] +
										'-' +
										match[3]
									);
								}
								return rowData.callBackNumber;
							}}
						/>
						<Column
							label='Dealer #'
							dataKey='dealerPhone'
							width={125}
							cellDataGetter={({ rowData }) => {
								const cleaned = (
									'' + rowData.dealerPhone
								).replace(/\D/g, '');
								const match = cleaned.match(
									/^(\d{3})(\d{3})(\d{4})$/
								);
								if (match) {
									return (
										'(' +
										match[1] +
										') ' +
										match[2] +
										'-' +
										match[3]
									);
								}
								return rowData.dealerPhone;
							}}
						/>
						<Column
							label='Return'
							dataKey='returnCallerCode'
							width={50}
							flexGrow={1}
							cellRenderer={({ cellData }) =>
								cellData === 'yes' ? (
									<Icon className={classes.green}>
										<Check />
									</Icon>
								) : (
									<Icon>
										<Remove />
									</Icon>
								)
							}
						/>
						<Column
							label='Notes'
							dataKey='internalNotes'
							width={50}
							flexGrow={1}
							cellRenderer={({ cellData }) =>
								cellData ? (
									<Tooltip title={cellData}>
										<Icon>
											<MoreHoriz />
										</Icon>
									</Tooltip>
								) : null
							}
						/>
						<Column
							label='Action Code'
							dataKey='actionCode'
							width={100}
							flexGrow={1}
						/>
						<Column
							label='Slip'
							dataKey='slip'
							width={50}
							flexGrow={1}
							cellRenderer={({ cellData, rowData }) => (
								<IconButton
									onClick={() =>
										props.handleSlipModalToggle(rowData)
									}
									className={classes.blue}>
									<Receipt />
								</IconButton>
							)}
						/>
						<Column
							label='Type'
							dataKey='mobile'
							width={100}
							flexGrow={1}
							cellRenderer={({ cellData, rowData }) =>
								typeRenderer(rowData)
							}
						/>
					</Table>
				)}
			</AutoSizer>
		</div>
	);
}

export default AdminSlipsTable;
