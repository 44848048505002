import React from 'react';

import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { DatePicker } from '@mui/lab';

import moment from 'moment';

import AdapterMoment from '@mui/lab/AdapterMoment';

import {
	Button,
	Dialog,
	DialogTitle,
	IconButton,
	TextField,
} from '@mui/material';

import { Close } from '@mui/icons-material';
import { DialogContent } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: '#ffffff !important',
	},
	titleBar: {
		backgroundColor: '#000352',
		color: '#ffffff',
		height: 60,
	},
	titleText: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
	},
	modalContent: {
		height: 200,
		width: 600,
		display: 'flex',
		justifyContent: 'center',
		flexDirection: 'column',
	},
	datePickerContainer: {
		display: 'flex',
		justifyContent: 'space-evenly',
		marginBottom: 30,
	},
}));

interface IDatePickerProps {
	datePickerOpen: boolean;
	handleDatePickerToggle: any;
	handleDateChange: (starDate: number, endDate: number) => void;
	startDate: string;
	endDate: string;
	setStartDate: (date: string) => void;
	setEndDate: (date: string) => void;
}

function DateSelector(props: IDatePickerProps): JSX.Element {
	const classes = useStyles();
	const [startDate, setStartDate] = React.useState<Date | null>(null);
	const [endDate, setEndDate] = React.useState<Date | null>(null);

	const handleDateChange = () => {
		props.handleDateChange(
			moment(startDate).valueOf(),
			moment(endDate).valueOf()
		);
		props.setStartDate(moment(startDate).format('MM/DD/YY'));
		props.setEndDate(moment(endDate).format('MM/DD/YY'));
	};

	return (
		<Dialog
			onClose={props.handleDatePickerToggle}
			open={props.datePickerOpen}
			disableEnforceFocus>
			<DialogTitle className={classes.titleBar}>
				<div className={classes.titleText}>
					<div>Select a Date Range</div>
				</div>
				<IconButton
					style={{ position: 'absolute' }}
					className={classes.closeButton}
					onClick={() => props.handleDatePickerToggle()}>
					<Close />
				</IconButton>
			</DialogTitle>
			<DialogContent className={classes.modalContent}>
				<LocalizationProvider dateAdapter={AdapterMoment}>
					<div className={classes.datePickerContainer}>
						<DatePicker
							label='Start Date'
							value={startDate}
							onChange={(date) => {
								setStartDate(date);
							}}
							renderInput={(params) => <TextField {...params} />}
						/>
						<DatePicker
							label='End Date'
							value={endDate}
							onChange={(date) => {
								setEndDate(date);
							}}
							renderInput={(params) => <TextField {...params} />}
						/>
					</div>
				</LocalizationProvider>
				<Button
					style={{
						backgroundColor:
							startDate === endDate ? '#dadad9' : '#000352',
						color: startDate === endDate ? '#707070' : '#ffffff',
						width: 100,
						margin: '0px auto',
					}}
					disabled={startDate === endDate}
					variant='outlined'
					onClick={() => handleDateChange()}>
					Submit
				</Button>
			</DialogContent>
		</Dialog>
	);
}

export default DateSelector;
